import Button from "@button";
import React from "react";


// const ButtonHeaderBar = React.forwardRef((props, ref) => {
//   return <Button
//     {...props}
//       ref={ref}
//     sx={{
//       color: 'currentColor',
//       whiteSpace: 'nowrap',
//       font: 'heading01',
//       transition: 'color 100ms',
//       textDecoration: props.underlined ? 'underline' : 'none',
//       pointerEvents: 'all'
//     }}
//   />
// });

const ButtonHeaderBar = Button.styled({
    sx: {
        color: 'currentColor',
        whiteSpace: 'nowrap',
        font: 'heading01',
        lineHeight: 1,
        transition: 'color 100ms',
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: p => p.underlined ? 'underline' : 'none'
    }
})

export default ButtonHeaderBar
