import React from 'react'
import {lin} from "@commerce-ui/core2";
import Button from "@button";
import Link from "@link";
import IconClose from "../icons/IconClose";
import styledBox from "../../styledBox";

const Root = styledBox({
  position: 'fixed',
  left: 0, bottom: 0, width: '100%',
  zIndex: 100,
  opacity: props => props.state === "OPEN" ? 1 : 0,
  display: props => props.state === "CLOSED" ? 'none' : 'block',
  transition: props => `opacity ${props.transitionTime}ms`,
  bg: 'cream700'
});
const GridBox = styledBox({
  px: 's8',
  display: 'grid',
  gridColumnGap: 's9',
  gridTemplateColumns: [
    'auto',
    'auto',
    '1fr 10fr 1fr',
    '1fr 10fr 1fr',
    '1fr 10fr 1fr'
  ],
  gridTemplateRows: [
    '60px auto 60px',
    '60px auto 60px',
    '64px',
    '64px',
    '64px',
  ]
});
const TextBox = styledBox("span", {
    font: 'body01',
    gridColumn: [
      1,
      1,
      2,
      2,
      2
    ],
    gridRow: [
      2,
      2,
      1,
      1,
      1
    ],
    textAlign: 'center',
    alignSelf: 'center',
    color: 'mono500',
    px: [
      's5',
      's5',
      null
    ]
  }
);
const ButtonBox = styledBox({
  gridColumn: [
    null,
    null,
    3,
    3,
    3
  ],
  gridRow: 1,
  alignSelf: 'center',
  justifySelf: 'end'
})
const CookieMessage = ({state, onCloseRequest, transitionTime}) => {
  return <Root transitionTime={transitionTime} state={state}>
    <GridBox>
      <TextBox>
        This website use cookies to give you the best service possible. By using this website, you agree to the use of cookies as described in our
        &nbsp;
        <Link href={"/terms-and-conditions"} sx={{
          textDecoration: 'underline'
        }}>Privacy Policy</Link>.
      </TextBox>
      <ButtonBox>
        <Button onClick={onCloseRequest} sx={{ width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <IconClose size={lin(16,24)}/>
        </Button>
      </ButtonBox>
    </GridBox>
  </Root>

};

export default CookieMessage;
