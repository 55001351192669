import products from "../products"
import algoliasearch from 'algoliasearch/lite';
import createImage from "../../helpers/createImage";
import {getLang} from "../../helpers/setLang";

const client = algoliasearch('QLDWIGVTAV', 'a4666971a2b9163bf9009a4660055eac');

async function fetchSearchResults(queryValue) {

  let index;
  let currencyCode;

  switch (getLang()) {
    case 'pl':
      index = client.initIndex('shopify_pln_products');
      currencyCode = "PLN";
      break;
    case 'uk':
      index = client.initIndex('shopify_gbp_products');
      currencyCode = "GBP";
      break;
    case 'eu':
      index = client.initIndex('shopify_eur_products');
      currencyCode = "EUR";
      break;
    default:
      index = client.initIndex('products');
      currencyCode = "USD";
      break;
  }

  let results = {
    products : []
  };



  // index.search(queryValue, {"hitsPerPage": 96}).then(({ hits }) => {
  //
  //   hits.forEach( el => {
  //
  //     let variants = [];
  //
  //     for (let step = 0; step < el.variants_count; step++) {
  //       variants.push(
  //         {
  //           available: (el.variants_inventory_count == 0 ? false : true)
  //         }
  //       );
  //     }
  //
  //     let product = {
  //       id: el.handle,
  //       title: el.title,
  //       tags: [],
  //       images: [
  //         createImage({
  //           id: el.image,
  //           altText: el.title,
  //           originalSrc: el.product_image,
  //           width: 468,
  //           height: 660
  //         })
  //       ],
  //       handle: el.handle,
  //       price: {
  //           currencyCode: currencyCode,
  //           amount: el.price.toString()
  //       },
  //       variants: variants
  //     };
  //
  //     results.products.push(product);
  //
  //   });
  //
  // });
  //
  // return results;

  const { hits } = await index.search(queryValue, {"hitsPerPage": 96});

    hits.forEach( el => {

      let variants = [];

      for (let step = 0; step < el.variants_count; step++) {
        variants.push(
          {
            available: (el.variants_inventory_count == 0 ? false : true)
          }
        );
      }

      let product = {
        id: el.handle,
        title: el.title,
        tags: [],
        primaryImage: el.product_image && createImage({
          id: el.image,
          altText: el.title,
          originalSrc: el.product_image,
          width: 468,
          height: 660
        }),
        handle: el.handle,
        price: {
            currencyCode: currencyCode,
            amount: el.price.toString()
        },
        variants: variants
      };

      results.products.push(product);

    });


  return results;
}

export default fetchSearchResults;
