import React, { useContext } from 'react'
import theme from '../components/theme'
import styled from '@emotion/styled'
import ProductCard from '../components/ProductCard'
import NextLink from 'next/link'
import { getLang } from '../helpers/setLang'
import ImageWithProducts from '../components/ImageWithProducts'
import ShopNowButton from '../components/ShopNowButton'
import createImage, { getImgixSrc } from '../helpers/createImage'
import { ModalWithProductsContext } from '../components/ModalWithProductsContext'
import NextImage from 'next/image'

import { ShopstoryProvider } from '@shopstory/core/dist/client/Shopstory'

const Button = styled.button(
  {
    ...theme.typography.heading01,
    textDecoration: 'none',
    border: 'none',
    display: 'inline-flex',
    height: '35px',
    color: 'white',
    lineHeight: '33px',
    textAlign: 'center',
    padding: '0 16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    background: 'black',
    appearance: 'none',
    boxSizing: 'border-box'
  },
  (props) =>
    props.variant === 'secondary' && {
      border: '1px solid black',
      background: 'transparent',
      color: 'black'
    }
)

const TextButton = styled.button(
  {
    textDecoration: 'none',
    border: 'none',
    display: 'inline-flex',
    background: 'transparent',
    appearance: 'none',
    boxSizing: 'border-box'
  },
  (props) => {
    return {
      ...theme.typography[props.font],
      textDecoration: props.underline ? 'underline' : 'none',
      color: props.isLight ? 'white' : 'black',
      transition: 'opacity 150ms',
      '&:hover': {
        opacity: 0.8
      }
    }
  }
)

const ProductCardShopstory = (props) => (
  <ProductCard
    {...props}
    hasLeftIndent={[
      props.additionalPadding,
      props.additionalPadding,
      props.additionalPadding,
      props.additionalPadding,
      props.additionalPadding
    ]}
    hasRightIndent={[
      props.additionalPadding,
      props.additionalPadding,
      props.additionalPadding,
      props.additionalPadding,
      props.additionalPadding
    ]}
  />
)

const ImageWithProductsShopstory = (props) => {
  const image = createImage({
    alt: props.image.fields.title,
    originalSrc: 'https:' + props.image.fields.file.url,
    width: props.image.fields.file.details.image.width,
    height: props.image.fields.file.details.image.height
  })

  const products = [props.product1, props.product2, props.product3].filter((p) => !!p)

  return <ImageWithProducts products={products} image={image} />
}

function InternalLinkProvider({ Component, componentProps, values }) {
  let href = '#'
  if (values.url) {
    const lang = getLang()
    const url = values.url.startsWith('/') ? values.url : `/${values.url}`
    href = `/${lang}${url}`
  }

  return (
    <NextLink href={href} passHref>
      <Component {...componentProps} />
    </NextLink>
  )
}

const imgixCustomLoader = ({ src, width }) => {
  return getImgixSrc(src, width)
}

function Image({ src, alt }) {
  return <NextImage loader={imgixCustomLoader} src={src} layout={'fill'} alt={alt} />
}

export const MBShopstoryProvider = (props) => {
  const modalWithProductsContext = useContext(ModalWithProductsContext)

  return (
    <ShopstoryProvider
      components={{
        Button: Button,
        ShopButton: ShopNowButton,
        TextButton: TextButton,
        ProductCard: ProductCardShopstory,
        ImageWithProducts: ImageWithProductsShopstory
      }}
      actions={{
        OverlayWithProducts: (values) => {
          const image = values.image
            ? createImage({
                alt: values.image.fields.title,
                originalSrc: 'https:' + values.image.fields.file.url,
                width: values.image.fields.file.details.image.width,
                height: values.image.fields.file.details.image.height
              })
            : false

          const products = [values.product1, values.product2, values.product3, values.product4, values.product5].filter(
            (p) => !!p
          )

          modalWithProductsContext.openDialogWithProducts(image, products)
        },
        MyAction: (values) => {
          console.log(values)
        }
      }}
      links={{
        internalLink: InternalLinkProvider
      }}
      Image={Image}
    >
      {props.children}
    </ShopstoryProvider>
  )
}
