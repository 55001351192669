import React from 'react'
import { lin } from '@commerce-ui/core2'
import ProductCard from '../ProductCard'
import PropTypes from 'prop-types'
import styledBox from '../../styledBox'

const TitleBox = styledBox({
  pt: lin('s12', 's13'),
  pb: lin('s10', 's13'),
  font: 'heading10',
  textAlign: 'center'
})

const GridBox = styledBox({
  position: 'relative',
  display: 'grid',
  gridColumnGap: 'gridGap',
  gridRowGap: lin('s8', 's7'),
  gridTemplateColumns: ['repeat(2, 1fr)', null, 'repeat(3, 1fr)', 'repeat(4, 1fr)']
})
const GridSliderBox = styledBox({
  position: 'relative',
  display: 'grid',
  gridColumnGap: 'containerMargin',
  gridRowGap: lin('s10', 's8'),
  '::-webkit-scrollbar': { display: 'none' },
  px: ['containerMargin', null, 0],
  gridTemplateColumns: ['unset', null, 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
  gridAutoColumns: ['75vw', null, 'unset'],
  gridAutoFlow: ['column', null, 'unset'],
  overflowX: ['scroll', null, 'visible'],
  ':after': {
    display: ['block', null, 'none'],
    content: "''",
    width: '0.1px'
  }
})

const ProductGrid = ({ products, isSliderOnMobile, title }) => {
  let productCards = products.map((product, i) => (
    <ProductCard
      key={i}
      product={product}
      oneLineTextOnMobile={isSliderOnMobile}
      hasLeftIndent={[
        isSliderOnMobile ? false : i % 2 === 0,
        isSliderOnMobile ? false : i % 2 === 0,
        i % 3 === 0,
        i % 4 === 0,
        i % 4 === 0
      ]}
      hasRightIndent={[
        isSliderOnMobile ? false : i % 2 === 1,
        isSliderOnMobile ? false : i % 2 === 1,
        i % 3 === 2,
        i % 4 === 3,
        i % 4 === 3
      ]}
    />
  ))

  return (
    <div>
      {title && <TitleBox>{title}</TitleBox>}
      {isSliderOnMobile ? <GridSliderBox>{productCards}</GridSliderBox> : <GridBox>{productCards}</GridBox>}
    </div>
  )
}

ProductGrid.propTypes = {
  products: PropTypes.array.isRequired,
  isSliderOnMobile: PropTypes.bool,
  title: PropTypes.string
}

export default ProductGrid
