import React from 'react'
import Box from "@commerce-ui/core2/Box";

import ButtonSerif from "../buttons/ButtonSerif";
import ButtonCategory from "../buttons/ButtonCategory";

import styledBox from "../../styledBox";

const MobileOverlayRoot = styledBox({
  color: 'white', pt: 's10', zIndex: 1, position: 'relative',
  'h3': {
    m: 0,
    fontWeight: 400,
    font: 'heading02'
  },
  'ul' : {
    p: 0,
    m: 0
  },
  'li' : {listStyle : 'none'}
});
const CategoriesBar = styledBox({
  display: 'flex',
  justifyContent: 'space-between',
  mt: 's10',
  mb: 's8',
});
const StoriesWrapper = styledBox({mb: 's8'})
const CollectionsWrapper = styledBox({
  display: 'flex',
  justifyContent: 'space-between',
  mt: 's10',
  mb: 's10'
})
const HandbagsWrapper = styledBox({
  display: 'flex',
  justifyContent: 'space-between',
  mt: 's10',
  mb: 's10'
})
const HeaderMobileShopOverlay = ({categories, stories, showSale, showBags}) =>
  <MobileOverlayRoot>
    <div>
      <CategoriesBar>
        <h3>Categories</h3>
        <ButtonSerif
          label={"Shop All"}
          href={"/category/all"}
        />
      </CategoriesBar>
      <ul>
        {
          showSale &&
            <li>
              <ButtonCategory label={"Sale"} href={"/category/sale"} />
            </li>
        }
        {
          categories.map((category, i) => {
            return <li
              key={i}
            >
              <ButtonCategory {...category} label2={category.quantity}/>
            </li>
          })
        }
      </ul>
    </div>
    <StoriesWrapper>
      <CategoriesBar>
        <h3>Stories</h3>
        <ButtonSerif
          label={"View All"}
          href={"/stories"}
        />
      </CategoriesBar>
      <Box as={'ul'}>
        {
          stories.map((story, i) => {
            return <Box
              as={"li"}
              key={i}
            >
              <ButtonCategory {...story}/>
            </Box>
          })
        }
      </Box>
    </StoriesWrapper>
    
    {showBags && <HandbagsWrapper>
      <h3> Handbags </h3>
      <ButtonSerif
        label={"View All"}
        href={"/handbags"}
      />
    </HandbagsWrapper>}

    <CollectionsWrapper>
      <h3> Collections </h3>
      <ButtonSerif
        label={"View All"}
        href={"/collections"}
      />
    </CollectionsWrapper>
  </MobileOverlayRoot>;

export default HeaderMobileShopOverlay