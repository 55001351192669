import React from 'react'

import {lin} from "@commerce-ui/core2";

import ButtonSerif from "../buttons/ButtonSerif"
import ButtonCategory from "../buttons/ButtonCategory"
import styledBox from "../../styledBox";

const Heading = styledBox({
  font: 'heading02',
  mb: 's8',
  position: 'relative'
});

const Root = styledBox({
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: [
    'none',
    null,
    'flex'
  ]
});
const Grid = styledBox({
  display: 'grid',
  gridTemplateColumns: [
    null,
    null,
    '160px 160px',
  ],
  pt: lin('s9','s12'),
  pb: lin('s8','s10'),
  justifyContent: 'center',
  margin: '0 auto',
  color: 'white',
  position: 'relative',
});

const SubGrid = styledBox('ul', {
  m: 0,
  p: 0,
  mb: 's8',
  display: 'inline-grid',
  gridTemplateColumns: 'repeat(2, 220px)',
  gridTemplateRows: props => `repeat(${props.rows}, auto)`,
  gridAutoFlow: 'column',
  "li" : {
    m: 0,
    p: 0,
    listStyle: 'none',
  }
})

const HeaderShopOverlay = ({ categories }) => {
  return <Root>
    <Grid>
      <div>
        <Heading>
          Categories
        </Heading>
        <SubGrid rows={Math.ceil(categories.length / 2 )}>
          {
            categories.map((category, i) => {
              return <li
                key={i}
              >
                <ButtonCategory {...category} />
              </li>
            })
          }
        </SubGrid>
        <ButtonSerif
          label={"Shop All"}
          href={"/category/all"}
        />
      </div>

      {/*<div>*/}
        {/*<Heading>*/}
          {/*Stories*/}
        {/*</Heading>*/}
        {/*<ul>*/}
          {/*{*/}
            {/*stories.map((story, i) => {*/}
              {/*return <li*/}
                {/*key={i}*/}
              {/*>*/}
                {/*<ButtonCategory {...story}/>*/}
              {/*</li>*/}
            {/*})*/}
          {/*}*/}
        {/*</ul>*/}
        {/*<ButtonSerif*/}
          {/*label={"View All"}*/}
          {/*href={"/stories"}*/}
        {/*/>*/}
      {/*</div>*/}
    </Grid>
    </Root>

};

export default HeaderShopOverlay
