import React, {useState, useEffect, useRef} from 'react'
import styledBox from "../../styledBox";
import Search, {useSearch} from "@commerce-ui/core2/Search2"
import Button from "@button";
import IconClose from "../icons/IconClose";
import Logo from "../Logo";
import ProductGrid from "../ProductGrid";
import Analytics from "../../helpers/analytics";


import {FitLoader} from "../PageLoader";
import Link from "@link"
import {lin} from "@commerce-ui/core2";
import fetchSearchResults from "../../data/shopify/fetchSearchResults";

const InputStyled = styledBox({
    display: 'flex',
    pointerEvents: 'all',
    mx: [
        'containerMargin',
        null,
        0
    ],
    pr: [
        40,
        null,
        0
    ],
    "input": {
        padding: 0, margin: 0,
        appearance: 'none',
        flexGrow: 1,
        border: 0,
        borderBottom: '1px solid',
        borderColor: 'black',
        height: 35,
        font: 'heading07',
        bg: 'transparent',
        borderRadius: 0,
        "::-webkit-search-cancel-button": {
            display: 'none'
        },
        ":focus": {
            outline: 'none'
        },
        zIndex: 1
    }
})
const Input = ({ autoFocus, controlRef, ...props}) => {
    const internalRef = useRef(null);
    const inputRef = controlRef || internalRef;

    useEffect(() => {
        if (autoFocus) {
            inputRef.current.focus();
        }
    }, []);

    return <InputStyled>
        <input
            {...props}
            onChange={e => {
                if (props.onChange) {
                    props.onChange(e.target.value, e);
                }
            }}
            ref={inputRef}
        />

        {/*<Button*/}
        {/*{...props.submitButtonProps}*/}
        {/*sx={{*/}
        {/*width: 35, height: 35,*/}
        {/*borderBottom: '1px solid',*/}
        {/*borderColor: 'black',*/}
        {/*display: 'flex',*/}
        {/*alignItems: 'center', justifyContent: 'center',*/}
        {/*'svg' : {*/}
        {/*transform: 'rotate(180deg)'*/}
        {/*}*/}
        {/*}}*/}
        {/*><IconArrow size={26}/></Button>*/}
    </InputStyled>
};

// const Input = React.forwardRef((props, ref) => {
//   console.log(props)
//   return <InputStyled>
//     <input
//       {...props}
//       ref={ref}
//       onChange={e => {
//         if (props.onChange) {
//           props.onChange(e.target.value, e);
//         }
//       }}
//     />
//
//   </InputStyled>
// })

const SearchRoot = styledBox({
    bg: 'cream',
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: [
        'auto auto 1fr',
        null,
        'auto auto auto 1fr'
    ]
})

const SearchHeader = styledBox({
    position: 'relative',
    height: 'headerBarHeight',
    gridTemplateColumns: '60px 1fr 60px',
    mx: 'conainerMargin',
    display: [
        'none',
        null,
        'grid'
    ]
})

const Loader = styledBox({
    pb: '40vh'
})

const ResultsInfo = styledBox({
    textAlign: 'center',
    font: 'heading09',
    mt: lin('s8', 's10'),
    mb: lin('s7', 's9')
})
const NoResultsWrap = styledBox({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    font: 'heading01',
    mt: lin('s10', 's7'),
    "span": {
        font: 'heading09',
        lineHeight: 1,
        mx: 's5',
        my: 's3'
    }
})
const LinksWrap = styledBox({
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    mx: 'containerMargin',
    whiteSpace: 'nowrap'
});
const NoResultsButton = ({children, onClick}) => <Button
    onClick={onClick}
    sx={{
        mx: 's5',
        my: 's3',
        font: 'heading01',
        lineHeight: 1
    }}
>
    {children}
</Button>
const BottomRoot = styledBox({
    textAlign: 'center',
    font: 'heading09',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: [
        's12',
        null,
        's10'
    ],
    'a': {
        textDecoration: 'underline'
    }
})
const Bottom = () => <BottomRoot>
    <div>
        Need help finding a piece? <br/>
        For further information, please <Link href={"/contact"}>contact</Link> our customer care.
    </div>
</BottomRoot>
const NoSearchInfo = ({onChange}) => {
    return <NoResultsWrap>
        <LinksWrap>
            <span>Try searching for:</span>
            {
                [
                    "Dress",
                    "Tailoring and Suits",
                    "Pants",
                    "Knitwear",
                    "Skirt"
                ].map((value, i) => <NoResultsButton
                    key={i}
                    onClick={() => {
                        console.log('click')
                        onChange(value)
                    }}
                >{value}</NoResultsButton>)
            }
        </LinksWrap>
        <Bottom/>
    </NoResultsWrap>
}
const ProductGridWrapperStyled = styledBox({
    opacity: props => props.loaded ? 1 : 0,
    transition: 'opacity 300ms',
    pb: 's12',
})
const ProductGridResults = ({products}) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setTimeout(() => setLoaded(true), 300)
        // setLoaded(true)
    })
    return <ProductGridWrapperStyled loaded={loaded}>
        <ResultsInfo>
            Search Results ({products && products.length})
        </ResultsInfo>
        <ProductGrid products={products}/>
    </ProductGridWrapperStyled>
};

const NoResultsRoot = styledBox({
    display: 'grid',
    gridTemplateRows: 'auto 1fr'
});

const NoResults = ({onChange}) => {
    return <NoResultsRoot><ResultsInfo>
        No results found
    </ResultsInfo>
        <NoSearchInfo onChange={onChange}/>
    </NoResultsRoot>
};

const Results = ({value, onChange}) => {
    const [isLoading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    const timeout = useRef(null);

    useEffect(() => {
        clearTimeout(timeout.current);

        if (value === "") {
            setResult(null);
            setLoading(false);
        } else {
            Analytics.search(value);

            timeout.current = setTimeout(() => {
                setLoading(true);
            }, 1000);

            fetchSearchResults(value).then((res) => {
                clearTimeout(timeout.current);
                setResult(res);
                setLoading(false);
            });
        }
    }, [value]);

    if (isLoading) {
        return <Loader><FitLoader r={50}/></Loader>;
    }

    if (!result || value === "") {
        return <NoSearchInfo onChange={onChange}/>;
    }

    if (result.products.length > 0) {
        return <ProductGridResults products={result.products}/>
    } else {
        return <NoResults onChange={onChange}/>
    }
};

const CloseWrap = styledBox({
    position: 'sticky',
    right: 0,
    top: 0,
    zIndex: 2,
    mx: [
        'containerMargin',
        null,
        null,
        's8'
    ]
});

const CloseInner = styledBox({
    position: 'absolute',
    right: 0, top: 0,
    height: 'headerBarHeight',
    display: 'flex', alignItems: 'center',
});

const Close = ({close}) => <CloseWrap>
    <CloseInner>
        <Button
            onClick={close}
            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 24, height: 24}}
        >
            <IconClose size={24}/>
        </Button>
    </CloseInner>
</CloseWrap>;

const LogoWrap = styledBox({
    width: 'headerLogoWidth', height: 'headerLogoHeight',
    alignSelf: 'center', justifySelf: 'center', cursor: 'pointer',
    gridColumn: 2
});

const StickyWrap = styledBox({
    position: 'sticky', top: 0,
    flexDirection: 'column',
    display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1,
    pointerEvents: 'none',
    mt: [
        0,
        null,
        's9'
    ]
});

const SearchInputWrap = styledBox({
    height: 'headerBarHeight',
    display: 'flex', alignItems: 'center',
    width: '100%', maxWidth: 380,
    ">div": {
        width: '100%'
    }
});

const GradientBackground = styledBox({
    position: 'absolute',
    top: 0, left: 0, width: '100%', height: lin(180, 260), pointerEvents: 'none',
    background: 'linear-gradient(0deg,rgba(252,250,247,0) 0%, rgba(252,250,247,1) 95%)',

});


const SearchOverlay = ({close}) => {
    const [value, setValue] = useState("");

    return (<SearchRoot>
            <Close close={close}/>

            <SearchHeader>
                <LogoWrap onClick={close}>
                    <Logo/>
                </LogoWrap>
            </SearchHeader>

            <StickyWrap>
                <GradientBackground/>
                <SearchInputWrap>
                    <Search
                        value={value}
                        debounceTime={250}
                        onChange={val => {
                            setValue(val)
                        }}
                        onSubmit={() => {
                            console.log('on submit');
                        }}
                        form={({inputProps, submitButtonProps}) => (
                            <Input
                                placeholder={"Collection, product, etc..."}
                                autoFocus={true}
                                {...inputProps}
                            />
                        )}
                    />
                </SearchInputWrap>
            </StickyWrap>
            <Results
                value={value}
                onChange={val => { setValue(val) }}
            />

        </SearchRoot>
    );
}


export default SearchOverlay
