import React from 'react'
import Box from "@commerce-ui/core2/Box";
import Button from "@button";
import ButtonSerif from "../buttons/ButtonSerif";
import styledBox from "../../styledBox";

const CollectionsRoot = styledBox("ul", {textAlign: 'center'});


const ViewBox = styledBox({
  position: 'absolute',
  left: '100%',
  opacity: p => p.show ? 1 : 0,
  ml: '0.5em',
  mt: '-0.15em',
  transition: 'opacity 200ms 50ms',
  font: 'body01'
})

const HeaderCollectionsButton = Button.styled({
  sx: {
    font: 'heading02',
    display: 'inline-flex',
    alignItems: 'center',
    height: 44
  },
  children: (p, s) => <>
    <div>{p.children}</div>
    <ViewBox show={s.hovered}>(View)</ViewBox>
  </>
});

const Root = styledBox({color: 'white'})
const Footer = styledBox({
  display: 'flex',
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: 'headerBarHeight',
  // border: '1px solid red',
  alignItems: 'center',
  justifyContent: 'center'
})

const HeaderCollectionsOverlay = ({links, viewAllHref}) => {

  return <Root>

      <CollectionsRoot>

        {
          links.map((collection, i) => <li
            key={i}
          >
            <HeaderCollectionsButton href={collection.href}>{collection.label}</HeaderCollectionsButton>
          </li>)
        }
      </CollectionsRoot>

      <Footer>
        <ButtonSerif
          label={"View All"}
          href={viewAllHref}
        />
      </Footer>

    </Root>
};

export default HeaderCollectionsOverlay
