import Box from "@commerce-ui/core2/Box";
import React from "react";
import Link from "@link";

const NewsletterCheckbox = React.forwardRef((props, ref) => {
  const {hasError, onChange, style, id} = props;
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    height: 35,
    ...style
  }}>
    <Box
      as={"input"}
      type={"checkbox"}
      id={id}
      sx={{
        appearance: 'none',
        border: '1px solid',
        borderColor: hasError ? 'red' : 'black',
        width: 16,
        height: 16,
        borderRadius: 0,
        position: 'relative',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        cursor: 'pointer',
        ":checked:after" : {
          content: `''`,
          position: 'absolute',
          width: 6,
          height: 6,
          borderRadius: '50%',
          bg: 'black',
        },
      }}
      ref={ref}
      onChange={onChange}
    />
    <Box
      as={"label"}
      htmlFor={id}
      sx={{
        font: 'body01',
        pl: 's7',
        color: hasError ? 'red' : null,
        ':hover' : {
          cursor: 'pointer'
        },
        "a" : {
          textDecoration: 'underline'
        }
      }}
    >
      I confirm that I have read and understand the <Link href={"/terms-and-conditions"}>privacy policy.</Link>
    </Box>
  </Box>
})

export default NewsletterCheckbox