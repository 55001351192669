import Button from "@button";
import React from "react";
import Box, {styledBox} from "@commerce-ui/core2/Box";

// const ButtonCategory = ({href, label, label2 = 'View' }) => {
//   return <Button
//     href={href}
//     sx={
//       {
//         display: 'inline-flex',
//         alignItems: 'center',
//         font: "heading01",
//         whiteSpace: 'nowrap'
//       }
//     }
//   >
//     {({ hovered }) => (
//       <>
//         <Box>{label}</Box>
//         <Box
//           sx={{
//             opacity: hovered ? "1" : "0",
//             ml: '0.75em',
//             transition: 'opacity 200ms 50ms',
//             font: 'heading04'}}
//         >
//           ({label2 })
//         </Box>
//       </>
//     )}
//   </Button>
// };

const LabelBox = styledBox({});
const Label2Box = styledBox({
    opacity: p => p.show ? "1" : "0",
    ml: '0.75em',
    transition: 'opacity 200ms 50ms',
    font: 'heading04',
});

const ButtonCategory = Button.styled({
    sx: {
        display: 'inline-flex',
        alignItems: 'center',
        font: "heading01",
        whiteSpace: 'nowrap'
    },
    children: (p, s) => <>
        <LabelBox>{p.label}</LabelBox>
        <Label2Box
            show={s.hovered}
        >
            (View)
        </Label2Box>
    </>
});

export default ButtonCategory
