import React from 'react'
import Button from '@button'
import { lin } from '@commerce-ui/core2'
import useCart from '@data/useCart'
import useCartModifier from '@data/useCartModifier'

import Select2 from '@commerce-ui/core2/Select2'

import Image from '@image'
import formatPrice from '../../helpers/formatPrice'
import IconChevron from '../icons/IconChevron'
import SelectableRow from '../SelectableRow'
import styledBox from '../../styledBox'

import Link from '@link'

const MOBILE_BOTTOM_DIALOG_MAX_HEIGHT = '60vh'

import Analytics from '../../helpers/analytics'
import ProductPrice from '../ProductPrice'

const quantityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9]

const THEME_BOX_SHADOW = '0 2px 4px rgba(0, 0, 0, 0.04)'

const MySelect = (props) => {
  return (
    <Select2
      width={['100vw', null, 64]}
      maxHeight={[MOBILE_BOTTOM_DIALOG_MAX_HEIGHT, MOBILE_BOTTOM_DIALOG_MAX_HEIGHT, null]}
      anchoredTo={['window', null, 'button']}
      placement={['bottom', null, 'bottomLeft']}
      root={{
        boxShadow: THEME_BOX_SHADOW
      }}
      {...props}
    />
  )
}
const ValueBox = styledBox({ ml: 's4', mr: 's6' })
const SelectButton = (props) => {
  return (
    <Button {...props} sx={{ font: 'body02', height: '40px', display: 'flex', alignItems: 'center' }}>
      <div>{props.label}</div>
      <ValueBox>{props.value}</ValueBox>
      <div>
        <IconChevron size={6} />
      </div>
    </Button>
  )
}

const Root = styledBox({
  display: 'grid',
  gridGap: lin('s7', 's8'),
  gridTemplateColumns: ['90px 1fr', null, null, '100px 1fr']
})
const Grid = styledBox({
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr'
})
const TextBox = styledBox({
  display: 'flex',
  justifyContent: 'space-between',
  font: 'heading01',
  lineHeight: 1.5,
  s: {
    color: 'mono600'
  }
})
const Title = styledBox({
  pr: 's4'
})
const SelectsBox = styledBox({ display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: 's9' })
const OneSizeBox = styledBox({ font: 'body02', height: '40px', display: 'flex', alignItems: 'center' })
const BottomBox = styledBox({
  alignSelf: 'end',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end'
  // pb: '0.25em'
})
const ExtraInfoBox = styledBox({
  ml: 's6',
  font: 'heading09',
  textAlign: 'right',
  span: { whiteSpace: 'nowrap', color: 'red' },
  div: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }
})
const Disc = styledBox({
  mx: 's5',
  width: '3px',
  height: '3px',
  borderRadius: '50%',
  bg: 'red',
  display: 'inline-block'
})

const ProductRow = ({ lineItem }) => {
  const cartModifier = useCartModifier()
  const cart = useCart()

  let isPreorder = lineItem.variant.product.tags.includes('preorder')

  return (
    <Root>
      <div>
        {lineItem.variant.product.primaryImage && (
          <Link href={'/product/' + lineItem.variant.product.handle}>
            <Image image={lineItem.variant.product.primaryImage} sizes={'100px'} />
          </Link>
        )}
      </div>
      <Grid>
        <TextBox>
          <Title>
            <Link href={'/product/' + lineItem.variant.product.handle}>
              {lineItem.variant.product.title}
              <br />
              {lineItem.variant.product.color.name}
            </Link>
          </Title>
          <div>
            <ProductPrice product={lineItem.variant.product} />
          </div>
        </TextBox>
        <SelectsBox>
          <div>
            {lineItem.variant.product.variants.length > 1 ? (
              <MySelect
                options={lineItem.variant.product.variants.map((v) => ({
                  label: v.size.label,
                  id: v.size.id,
                  disabled: !v.available,
                  quantityAvailable: v.quantityAvailable
                }))}
                button={({ value }) => <SelectButton label={'Size'} value={value.label} />}
                value={lineItem.variant.size}
                onChange={(val) => {
                  let oldVariant, newVariant
                  let oldQuantity

                  cartModifier
                    .replace(
                      cart.lineItems.map((item) => {
                        if (item.variant.sku === lineItem.variant.sku) {
                          oldVariant = item.variant
                          oldQuantity = lineItem.quantity
                          newVariant = lineItem.variant.product.variants.find((element) => element.size.id === val.id)
                          return {
                            variant: newVariant,
                            quantity: lineItem.quantity
                          }
                        } else {
                          return item
                        }
                      })
                    )
                    .then((response) => {
                      const newLineItem = response.cart.lineItems.find((e) => e.variant.size.id === val.id)

                      Analytics.removeFromBag(oldVariant, oldQuantity)
                      Analytics.addToBag(newLineItem.variant, newLineItem.quantity)
                    })
                }}
                selectable={<SelectableRow shortOnDesktop />}
              />
            ) : (
              <OneSizeBox>One Size</OneSizeBox>
            )}
          </div>

          <div>
            <MySelect
              options={quantityOptions.slice(0, lineItem.variant.quantityAvailable)}
              button={({ value }) => <SelectButton label={'Qty'} value={value} />}
              value={lineItem.quantity}
              onChange={(val) => {
                let diff = 0
                let variant

                cartModifier
                  .replace(
                    cart.lineItems.map((item) => {
                      if (item.variant.sku === lineItem.variant.sku) {
                        diff = val - lineItem.quantity
                        variant = lineItem.variant

                        return {
                          variant: lineItem.variant,
                          quantity: val
                        }
                      } else {
                        return item
                      }
                    })
                  )
                  .then((response) => {
                    console.log(response)

                    if (diff > 0) {
                      Analytics.addToBag(variant, diff)
                    } else if (diff < 0) {
                      Analytics.removeFromBag(variant, -diff)
                    }
                  })
              }}
              selectable={<SelectableRow shortOnDesktop />}
            />
          </div>
        </SelectsBox>
        <BottomBox>
          <Button
            sx={{ color: 'mono500', textDecoration: 'underline', font: 'body02' }}
            onClick={() => {
              cartModifier.remove(lineItem.variant)

              Analytics.removeFromBag(lineItem.variant, lineItem.quantity)
            }}
          >
            remove
          </Button>

          <ExtraInfoBox>
            <div>
              {lineItem.variant.quantityAvailable === 1 && <span>Only 1 item left</span>}
              {/*{*/}
              {/*lineItem.variant.isLowStock &&*/}
              {/*<span>Only a few left</span>*/}
              {/*}*/}
              {lineItem.variant.quantityAvailable === 1 && lineItem.variant.isFinalSale && <Disc />}
              {lineItem.variant.isFinalSale && <span>Final Sale</span>}
            </div>
            {isPreorder && <div>{lineItem.variant.product.preorderMessage ?? 'Preorder'}</div>}
          </ExtraInfoBox>
        </BottomBox>
      </Grid>
    </Root>
  )
}

export default ProductRow
