import App from 'next/app'
import Head from 'next/head'
import React, { useState, useEffect, useRef } from 'react'
import HeaderBar from '../components/Header/HeaderBar'
import Root from '@commerce-ui/core2/Root'
import theme from '../components/theme'
import Footer from '../components/Footer'

import CookieMessage from '../components/CookieMessage'
import Bag from '../components/Bag'

import StoreProvider from '@data/StoreProvider'
import styledBox from '../styledBox'

import { DefaultSeo } from 'next-seo'

import MenuLayout from '@commerce-ui/core2/MenuLayout'

import runOnRouteChange from '@commerce-ui/core2/runOnRouteChange'

import setLang, { getLangFromRouter } from '../helpers/setLang'

import { useRouter } from 'next/router'
import PageLoader from '../components/PageLoader'
import NewsletterPopup from '../components/NewsletterPopup'

import { ModalWithProductsContext } from '../components/ModalWithProductsContext'

import Analytics from '../helpers/analytics'

import DialogWithProducts from '../components/ImageWithProducts/DialogWithProducts'

import { MBShopstoryProvider } from '../shopstory/MBShopstoryProvider'

//
// const ShopCursorStyled = styledBox({
//     position: 'fixed',
//     top: 0, left: 0,
//     font: 'heading14',
//     pointerEvents: 'none',
//     pl: 30,
//     zIndex: 10001,
//     transition: 'opacity 100ms'
// })

const GlobalCursor = () => (
  <div
    id={'global-cursor'}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
      paddingLeft: 30,
      zIndex: 10001,
      transition: 'opacity 100ms',
      ...theme.typography.heading14
    }}
  />
)

const AppLoaderStyled = styledBox({
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  transition: 'opacity 300ms',
  opacity: 0,
  '.spinner': {
    opacity: 0,
    transition: 'opacity 300ms 1000ms'
  },
  '&.visible': {
    pointerEvents: 'all',
    opacity: 1,
    '.spinner': {
      opacity: 1,
      transition: 'opacity 300ms 0'
    }
  }
})
const AppLoader = () => (
  <AppLoaderStyled id={'app-loader'}>
    <PageLoader />
  </AppLoaderStyled>
)

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  setLang(getLangFromRouter(router))

  const COOKIE_TRANSITION_TIME = 300

  const [bagOpen, setBagOpen] = useState(false)

  const [modalWithProductsOpen, setModalWithProductsOpen] = useState(false)
  const [modalWithProductsData, setModalWithProductsData] = useState({
    image: false,
    products: false
  })

  const popupTimeout = useRef(null)
  const [newsletterPopupOpen, setNewsletterPopupOpen] = useState(false)

  const [cookiesState, setCookiesState] = useState('CLOSED') // "CLOSING", "CLOSED", "OPENING", "OPENED"

  // const openCookies = () => {
  //     setCookiesState("OPENING");
  //     setTimeout(() => setCookiesState("OPEN"), COOKIE_TRANSITION_TIME);
  // };

  const closeCookies = () => {
    window.localStorage.siteCookiesDismissed = 'true'
    setCookiesState('CLOSING')
    setTimeout(() => setCookiesState('CLOSED'), COOKIE_TRANSITION_TIME)
  }

  const openNewsletterPopup = () => {
    setNewsletterPopupOpen(true)
  }

  const closeNewsletterPopup = () => {
    window.localStorage.newsletterPopupDismissed = 'true'
    setNewsletterPopupOpen(false)
  }

  useEffect(() => {
    Analytics.pageview(router.asPath)

    const wrap = document.getElementById('app-wrapper')
    const loader = document.getElementById('app-loader')

    const handleRouteChange = (url) => {
      if (url.length <= 3) {
        popupTimeout.current = setTimeout(() => {
          openNewsletterPopup()
        }, 4000)
      }

      runOnRouteChange()
      if (wrap && loader) {
        wrap.style.opacity = 0
        loader.classList.add('visible')
      }
    }

    const handleRouteComplete = (url) => {
      if (wrap && loader) {
        wrap.style.opacity = null
        loader.classList.remove('visible')
      }

      Analytics.pageview(url)
    }

    if (router.asPath.length <= 3) {
      popupTimeout.current = setTimeout(() => {
        openNewsletterPopup()
      }, 4000)
    } else if (window.localStorage.newsletterPopupDismissed !== 'true') {
      popupTimeout.current = setTimeout(() => {
        openNewsletterPopup()
      }, 15000)
    }

    if (window.localStorage.siteCookiesDismissed !== 'true') {
      setCookiesState('OPEN')
    }

    if (typeof window === 'undefined') {
      /// TO MOVE
      global.__BROWSER__ = false
      global.__DEV__ = true
    } else {
      window.__BROWSER__ = true
      window.__DEV__ = true
    }

    window.dataLayer = window.dataLayer || []

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteComplete)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteComplete)
      if (popupTimeout.current) {
        clearTimeout(popupTimeout.current)
      }
    }
  }, [])

  if (router.isFallback) {
    return <PageLoader />
  }

  const modalWithProductsContextParams = {
    modalWithProductsContextParam: modalWithProductsOpen,

    openDialogWithProducts: (image, products) => {
      setModalWithProductsData({
        image: image,
        products: products
      })
      setModalWithProductsOpen(true)
    }
  }

  if (Component.hideAll) {
    return (
      <StoreProvider>
        <DefaultSeo dangerouslySetAllPagesToNoFollow={true} dangerouslySetAllPagesToNoIndex={true} />
        <ModalWithProductsContext.Provider value={modalWithProductsContextParams}>
          <MBShopstoryProvider>
            <Root theme={theme}>
              <div id={'app-wrapper'}>
                <Component {...pageProps} />
              </div>
              {modalWithProductsData.products.length > 0 && (
                <DialogWithProducts
                  image={modalWithProductsData.image}
                  products={modalWithProductsData.products}
                  withImagePreview={modalWithProductsData.image ? true : false}
                  isOpen={modalWithProductsOpen}
                  setOpen={setModalWithProductsOpen}
                />
              )}
            </Root>
          </MBShopstoryProvider>
        </ModalWithProductsContext.Provider>
      </StoreProvider>
    )
  }

  return (
    <StoreProvider>
      <DefaultSeo dangerouslySetAllPagesToNoFollow={true} dangerouslySetAllPagesToNoIndex={true} />
      <ModalWithProductsContext.Provider value={modalWithProductsContextParams}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

        <MBShopstoryProvider>
          <Root theme={theme}>
            <DefaultSeo {...pageProps.globalData?.seo} />

            <MenuLayout>
              <MenuLayout.MenuBar takesSpace={false}>
                {!Component.hideHeader && (
                  <HeaderBar
                    data={pageProps.globalData?.header}
                    mode={pageProps.headerTheme ?? Component.headerTheme}
                    onBagClick={() => {
                      setBagOpen(!bagOpen)
                    }}
                  />
                )}
              </MenuLayout.MenuBar>
              <div id={'app-wrapper'}>
                <Component {...pageProps} />
              </div>
              {modalWithProductsData.image ||
                (modalWithProductsData.products && (
                  <DialogWithProducts
                    image={modalWithProductsData.image}
                    products={modalWithProductsData.products}
                    withImagePreview={modalWithProductsData.image ? true : false}
                    isOpen={modalWithProductsOpen}
                    setOpen={setModalWithProductsOpen}
                  />
                ))}
              <AppLoader />
              {!Component.hideFooter && <Footer {...pageProps.globalData?.footer} />}
              <CookieMessage
                state={cookiesState}
                onCloseRequest={closeCookies}
                transitionTime={COOKIE_TRANSITION_TIME}
              />
              <NewsletterPopup isOpen={newsletterPopupOpen} close={closeNewsletterPopup} />
              <Bag isOpen={bagOpen} setOpen={setBagOpen} />
            </MenuLayout>
          </Root>
        </MBShopstoryProvider>

        <GlobalCursor />
      </ModalWithProductsContext.Provider>
    </StoreProvider>
  )
}

// ANDRZEJ'S EDIT: For now I removed this method to enable static site optimization. I just import globalData statically. To be done later.

//  TO DO: FETCHING GLOBAL DATA OUT OF APP.JS
// MyApp.getInitialProps = async (appContext) => {
//     // calls page's `getInitialProps` and fills `appProps.pageProps`
//     const appProps = await App.getInitialProps(appContext);
//     const globalData = await fetchGlobalData();
//     return {...appProps, globalData}
// };

export default MyApp
