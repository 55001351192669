import React, {useState, useRef} from 'react'
import Button from "@button";
import Box from "@commerce-ui/core2/Box";
import Link from "@link";

import styledBox from "../../styledBox";
import IconArrow from "../icons/IconArrow";
import Analytics from "../../helpers/analytics";

const Root = styledBox({
    // pt: lin('s10','s12'),
    // pb: lin('s10','s11'),
});
const Title = styledBox({
  font: 'heading01'
})
const inputHeight = 36
const InputWrapper = styledBox({
  position: 'relative',
})


const Newsletter = () => {

  const STATES = ["READY", "INPUT_HAS_ERROR", "BUSY", "SENT_SUCCESS", "SENT_ERROR"];

  const refCheckbox = useRef(null)
  const refInput = useRef(null)

  const [firstFocus, setFirstFocus] = useState(false);
  const [policyAcceptanceError, setPolicyAcceptanceError] = useState(false);
  const [mainState, setMainState] = useState(STATES[0]);
  const [errorMessage, setErrorMessage] = useState(null)

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("### NEWSLETTER SUBMIT ###", refInput.current.value);

    if(!refCheckbox.current.checked) {
      setPolicyAcceptanceError(true);
      return
    }

    setMainState(STATES[2]);

    const email = refInput.current.value;

    let _data = {
        "profiles":[
          {
            "email": refInput.current.value ,
            "source" : "website_footer"
          }
        ]
      };

    fetch('/api/klaviyo_subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data),
    })
      .then(async (response)=> {

        let res = await response.json();

        if(response.status === 200) {
          setMainState(STATES[3]);
          setTimeout(()=> { setMainState(STATES[0]) }, 10000)

          Analytics.newsletterSignup(email);

        } else {
          setMainState(STATES[4]);
          setErrorMessage(res.detail);
          setTimeout(()=> { setMainState(STATES[0]) }, 10000)
        }
      })
      .catch((error) => {
        setMainState(STATES[4]);
        setErrorMessage('An error occurred, please refresh this page and try again.');
        setTimeout(()=> { setMainState(STATES[0]) }, 10000)
        console.error('Error:', error);
      });
  }

  return <Root>
    <Title>Newsletter</Title>

    {
      mainState === STATES[0] &&
      <>
        <form
          onSubmit={onSubmit}
          action={""}
          id="email_signup"
        >
          <InputWrapper>
            <Box
              as={'input'}
              placeholder={"Email Address"}
              type={"email"}
              required
              sx={{
                appearance: 'none',
                bg: 'transparent',
                borderBottom: '1px solid',
                borderColor: 'black',
                height: inputHeight,
                width: '100%',
                font: 'heading01',
                fontSize: 16,
                borderRadius: 0
              }}
              onFocus={() => setFirstFocus(true)}
              // id={"k_id_email"}
              ref={refInput}
            />
            <Button
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                height: inputHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              type={'submit'}
            ><IconArrow pointRight size={24}/></Button>
          </InputWrapper>
        </form>

        <Box
          sx={{
            display: firstFocus ? 'flex' : 'none',
            alignItems: 'start',
            mt: 's6'
          }}
        >
          <Box
            as={"input"}
            type={"checkbox"}
            id={"newsletter-checkbox"}
            sx={{
              appearance: 'none',
              border: '1px solid',
              borderColor: policyAcceptanceError ? 'red' : 'black',
              width: 16,
              height: 16,
              borderRadius: 0,
              position: 'relative',
              flexShrink: 0,
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              cursor: 'pointer',
              mt: 2,
              ":checked:after" : {
                content: `''`,
                position: 'absolute',
                width: 6,
                height: 6,
                borderRadius: '50%',
                bg: 'black',
              },
            }}
            ref={refCheckbox}
            onChange={() => refCheckbox.current.value ? setPolicyAcceptanceError(false) : null}
          />
          <Box
            as={"label"}
            htmlFor={"newsletter-checkbox"}
            sx={{
              font: 'body01',
              pl: 's7',
              color: policyAcceptanceError ? 'red' : null,
              ':hover' : {
                cursor: 'pointer'
              },
              "a" : {
                textDecoration: 'underline'
              }
            }}
          >
            I confirm that I have read and understand the <Link href={"/terms-and-conditions"}>privacy policy.</Link>
          </Box>

        </Box>


      </>
    }

    {
      mainState === STATES[2] &&
      <Box sx={{
        font: 'heading02',
        mt: 's4',
        color: 'mono600'
      }}>
        Sending...
      </Box>
    }
    {
      mainState === STATES[3] &&
      <Box sx={{
        font: 'heading02',
        mt: 's4'
      }}>
        Thank you for subscribing.
      </Box>
    }
    {
      mainState === STATES[4] &&
      <Box sx={{
        font: 'heading02',
        mt: 's4',
        color: 'red'
      }}>
        {errorMessage}
      </Box>
    }


  </Root>

};
export default Newsletter
