import Button from "@button";
import React from "react";

// const ButtonSerif = ({href, label}) => <Button
//   href={href}
//   sx={({hovered}) => ({
//     display: 'inline-flex',
//     font: 'heading03',
//     transition: '200ms 50ms',
//     borderBottom: hovered ? '1px solid currentColor': '1px solid transparent',
//   })
//   }
// >
//   {label}
// </Button>;

const ButtonSerif = Button.styled({
    sx: {
        display: 'inline-flex',
        font: 'heading03',
        transition: '200ms 50ms',
        borderBottom: (p, s) => s.hovered ? '1px solid currentColor': '1px solid transparent',
    },
    children: p => p.label
})


export default ButtonSerif
