import React from 'react'
import Container from '@commerce-ui/core2/Container'
import Logo from '../Logo'
import ButtonText from '../buttons/ButtonText'
import { styled } from '../stitches/stitches'

import Newsletter from '../Newsletter'

const _copyright = <>©Magda&nbsp;Butrym</>

const Root = styled('div', {
  lin: {
    mt: ['$10', '$12'],
    mb: ['$10', '$11']
  }
})

const LogoBox = styled('div', {
  lin: {
    mb: ['$10', '$11']
  }
})

const GridBox = styled('div', {
  lin: {
    gridRowGap: ['$9', '$10'],
    gridColumnGap: 'GRIDGAP'
  },
  display: 'grid',
  '@md': {
    gridTemplateColumns: 'repeat(3,1fr)'
  }
})

const SecondCol = styled('div', {
  display: 'grid',
  gridRow: 3,
  gridTemplateColumns: '1fr 1fr',
  '@md': {
    gridRow: 'auto',
    gridTemplateColumns: '1fr'
  }
})

const SecondColContent = styled('div', {
  alignSelf: 'end',
  gridRow: 1,
  gridColumn: 1,
  '@md': {
    gridRow: 'auto',
    gridColumn: 'auto'
  }
})

const ThirdCol = styled('div', {
  display: 'grid',
  '@md': {
    gridTemplateRows: '1fr auto'
  }
})

const CopyrightBoxDesktop = styled('div', {
  projectFont: 'heading01',
  color: '$mono500',
  display: 'none',
  justifyContent: 'space-between',
  '@md': {
    display: 'flex'
  }
})

const CopyrightBoxMobile = styled('div', {
  projectFont: 'heading01',
  color: 'mono500',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '@md': {
    display: 'none'
  }
})

const Footer = ({ firstColumnItems, secondColumnItems, socialsItems }) => {
  if (!firstColumnItems && !secondColumnItems && !socialsItems) return null

  return (
    <Root>
      <Container>
        <LogoBox>
          <Logo />
        </LogoBox>

        <GridBox>
          <div>
            {firstColumnItems.map((item, i) => (
              <div key={i}>
                <ButtonText href={item.href}>{item.label}</ButtonText>
              </div>
            ))}
          </div>

          <SecondCol>
            <div>
              {secondColumnItems.map((item, i) => (
                <div key={i}>
                  <ButtonText href={item.href}>{item.label}</ButtonText>
                </div>
              ))}
            </div>
            <SecondColContent>
              {socialsItems.map((item, i) => (
                <li key={i}>
                  <ButtonText href={item.href} target={'_blank'} external={true}>
                    {item.label}
                  </ButtonText>
                </li>
              ))}
            </SecondColContent>
          </SecondCol>

          <ThirdCol>
            <Newsletter />
            <CopyrightBoxDesktop>{_copyright}</CopyrightBoxDesktop>
          </ThirdCol>

          <CopyrightBoxMobile>{_copyright}</CopyrightBoxMobile>
        </GridBox>
      </Container>
    </Root>
  )
}
export default Footer
