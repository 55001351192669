import React, {useState} from 'react'
import Button from "@button";
import styledBox from "../../styledBox";

const Root = styledBox({
  display: 'flex',
  alignItems: 'center',
  font: 'heading01',
});
const Label = styledBox({
  transition: 'color 100ms',
  // display: props => [
  //   !props.bagIsEmpty ? 'none' : 'block',
  //   !props.bagIsEmpty ? 'none' : 'block',
  //   'block',
  //   'block',
  //   'block'
  // ],
  display: [
      'none',
      null,
      'block',
  ],
  marginRight: props => props.bagIsEmpty ? null: 16
});
const DiscWrapper = styledBox({
  height: '24px',
  width: props => props.bagIsEmpty ? 0 : 24 + 'px',
  transition: `width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`
});
const Disc = styledBox({
  font: 'label01',
  position: 'absolute',
  right: '0',
  display: 'flex',
  width: '24px',
  height: '24px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  color: props => props.isWhite ? 'black' : 'white',
  bg: props => props.isWhite ? 'white' : 'black',
  transform: props => props.bagIsEmpty ? 'scale(0.75,0.75)' : 'none',
  opacity: props => props.bagIsEmpty ? 0 : 1,
  transition: props => props.bagIsEmpty ? 'opacity 100ms, transform 100ms, background 300ms' : 'opacity 200ms 100ms, transform 200ms 100ms, background 300ms'
});

const ButtonBag = ({quantity, isWhite, onClick}) => {
  return <Button onClick={onClick} sx={{pointerEvents: 'all'}}>
    <Root>
      <Label>Bag</Label>
      <DiscWrapper>
        <Disc>
          {quantity ?? 0}
        </Disc>
      </DiscWrapper>
    </Root>
  </Button>
};

export default ButtonBag