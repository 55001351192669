import React, {useState} from 'react'
import Box from "@commerce-ui/core2/Box";
import Dialog from "@commerce-ui/core2/Dialog";
import Button from "@button";
import Container from "@commerce-ui/core2/Container";
import Link from "@link";
import {lin} from "@commerce-ui/core2";
import ButtonHeaderBar from "../buttons/ButtonHeaderBar";
import Logo from "../Logo";
import useCart from "@data/useCart";
import formatPrice from "../../helpers/formatPrice";
import IconClose from "../icons/IconClose";
import putLineItemsQuantity from "../../helpers/putLineItemsQuantity";
import styledBox from "../../styledBox";
import ProductRow from "./ProductRow";
import {getLang} from "../../helpers/setLang"
import {LogoInBar} from "../Header/HeaderBar";

const LedgerRowRoot = styledBox({
  display: 'flex',
  justifyContent: 'space-between',
  my: props => props.isTotal ? lin('s6', 's7') : 0,
  font: 'heading01',
  'span': {
    font: 'heading04'
  }
})

const LedgerRow = ({label, value, isTotal}) => {
    return <LedgerRowRoot isTotal={isTotal}>
        <div>{label}</div>
        <div>{value}</div>
    </LedgerRowRoot>

}

const HeaderRoot = styledBox({
  height: 'headerBarHeight',
  display: 'grid',
  gridTemplateColumns:
    '1fr auto 1fr',
  alignItems: 'center',
  color: 'black',
})
const HeaderLeftCol = styledBox({
  alignItems: 'center', display: 'flex',
  'span.quantity' : {font: 'heading04'},
  'span' : {font: 'heading01'}
})
const CheckoutFooter = styledBox({
  mt: lin('s8', 's9'),
  font: 'body02',
  'p': {},
  'span': {
    color: 'mono500'
  }
})


const Root = styledBox({ pb: lin('s10', 's11') })
const EmptyBag = styledBox({font: 'heading01', display: 'flex', alignItems: 'center', justifyContent: 'center', pb: lin('s12', 's14'), pt: 's10'})
const MainGrid = styledBox({
  display: 'grid',
  gridTemplateColumns: [
    '1fr',
    '1fr',
    '1fr',
    '420px 1fr 340px',
  ],
  gridGap: 'gridGap',
  maxHeight: '100%'
})
const ProductColumn = styledBox({
  overflowY: 'auto',
  maxHeight: ['none', 'none', '400px'],
  display: 'grid',
  gridGap: 'gridGap',
  pr: [
    null,
    null,
    'gridGap'
  ],
  alignItems: 'start'
})
const CheckoutColumn = styledBox({
  gridColumn: [
    1, 1, 3
  ]
})
const CheckoutTitle = styledBox({font: 'heading02', mb: lin('s8', 's9'), display: ['none', 'none', 'block']})
const Bag = ({isOpen, setOpen}) => {

    const cart = useCart();

    const lang = getLang();

    return <Dialog
        width={'100%'}
        height={['100%', '100%', 'auto']}
        isOpen={isOpen}
        placement={[null, null, "top"]}
        anchoredTo={"window"}
        onClose={() => {
            setOpen(false);
        }}
        root={{
            height: [
                '100%',
                '100%',
                'auto'
            ],
            bg: 'cream'
        }}
        header={
            <div>
                <Container>
                    <HeaderRoot>

                        {/*<HeaderLeftColMobile>*/}

                          {/*<ItemsQuantityBox>*/}
                          {/*{*/}
                          {/*cart &&*/}
                          {/*putLineItemsQuantity(cart.lineItems)*/}
                          {/*}*/}
                          {/*{*/}
                          {/*cart === null &&*/}
                          {/*"0"*/}
                          {/*}*/}
                          {/*</ItemsQuantityBox>*/}

                        {/*</HeaderLeftColMobile>*/}
                        <HeaderLeftCol>
                            <span>Bag</span>&nbsp;
                            <span className={'quantity'}>
                              {
                                cart &&
                                '(' + putLineItemsQuantity(cart.lineItems) + ')'
                              }
                              {
                                cart === null &&
                                  "(0)"
                              }
                            </span>
                        </HeaderLeftCol>
                        <div>
                            <LogoInBar/>
                        </div>
                        <Button sx={{display: 'flex', width: '24px', justifySelf: 'end'}} onClick={() => setOpen(false)}>
                          <IconClose size={24}/>
                        </Button>
                    </HeaderRoot>
                </Container>
            </div>
        }
    >
        {
          cart === null &&
            <EmptyBag>
              Your bag is empty.
            </EmptyBag>
        }
        {
          cart &&
          <Root>
            <Container>
              {
                cart.lineItems.length > 0 &&
                <MainGrid>
                  <ProductColumn>
                    {cart.lineItems.map(lineItem => {
                        return <ProductRow key={lineItem.variant.sku} lineItem={lineItem}/>
                      }
                    )}
                  </ProductColumn>
                  <CheckoutColumn>
                    <CheckoutTitle>Order Summary</CheckoutTitle>
                    <LedgerRow
                      label={<>Pieces <span>({putLineItemsQuantity(cart.lineItems)})</span></>}
                      value={formatPrice(cart.subtotalPrice)}
                    />
                    <LedgerRow
                      label={`Shipping`}
                      value={`Calculated at checkout`}
                    />
                    <LedgerRow
                      label={`Total`}
                      value={formatPrice(cart.subtotalPrice)}
                      isTotal
                    />
                    <Button sx={{
                      font: 'heading01',
                      bg: 'black',
                      color: 'white',
                      width: '100%',
                      textAlign: 'center',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                            href={cart.checkoutUrl}
                            // target={"_blank"}
                            external={true}
                    >
                      Checkout
                    </Button>

                    <CheckoutFooter>
                      {
                        lang === 'us' && <p>Free shipping on orders over 650 <>USD</></p>
                      }
                      {
                        lang === "eu" && <p>Free shipping on orders over 600 <>EUR</></p>
                      }
                      {
                        lang === "pl" && <p>Free shipping on orders over 2500 <>PLN</></p>
                      }
                      {
                        lang === "uk" && <p>Free shipping on orders over 550 <>GBP</></p>
                      }
                      <p><span>Read more about our full <Link href={"/returns-and-exchanges"} style={{textDecoration: 'underline'}}>Return and refund policy.</Link></span></p>
                    </CheckoutFooter>
                  </CheckoutColumn>
                </MainGrid>
              }
            </Container>
          </Root>
        }
    </Dialog>

};


export default Bag;
