import React from 'react'
import Box from '@commerce-ui/core2/Box'
import { lin } from '@commerce-ui/core2'
import Button from '@button'
import Image from '@image'
import putImageSizes from '../../helpers/putImageSizes'
import ProductPrice from '../ProductPrice'
import styledBox from '../../styledBox'
import { styled } from '../../components/stitches/stitches'
import Video from '../Video'

const ImageRoot = styled('div', {
  position: 'relative',
  pointerEvents: 'none'
})

const ImageAlternateBox = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transition: 'opacity 100ms',
  opacity: 0,
  variants: {
    hovered: {
      true: {
        opacity: 1
      }
    }
  }
})

const TagOverlay = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const TagInner = styled('div', {
  transform: 'rotate(-90deg)',
  position: 'absolute',
  width: '100%',
  paddingBottom: '100%'
})

//todo stitches
const TagText = styledBox({
  position: 'absolute',
  bottom: lin('s6', 's7'),
  textAlign: 'center',
  width: '100%',
  whiteSpace: 'nowrap',
  font: 'body01',
  color: 'mono600'
})
const BottomBox = styledBox({
  pt: (props) => (props.theme === 'default' ? lin('s6', 's8') : lin('s7', 's9')),
  pb: (props) =>
    props.theme === 'default'
      ? [props.oneLineTextOnMobile ? 's6' : 0, props.oneLineTextOnMobile ? 's6' : 0, 0]
      : lin('s7', 's9'),
  ml: (props) => (props.theme === 'default' ? props.leftIndent : lin('s7', 's9')),
  mr: (props) => (props.theme === 'default' ? props.rightIndent : lin('s7', 's9'))
})
const SizesBox = styledBox({
  font: 'body01',
  width: '100%',
  pr: '5em',
  opacity: (props) => (props.hovered ? 1 : 0),
  transition: 'opacity 100ms',
  display: ['none', 'none', 'flex'],
  flexWrap: 'wrap',
  color: 'mono400'
})
const SizeBox = styledBox({
  color: (props) => (props.available ? 'mono700' : 'mono400'),
  pr: '1.25em',
  py: '0.125em',
  display: 'inline'
  // ":hover" : {color: 'mono800'}
})

const noImageElem = (
  <Box
    sx={{
      bg: 'imageBg',
      paddingBottom: '140.84507042253523%',
      position: 'relative'
    }}
  >
    <Box
      sx={{
        color: 'mono500',
        font: 'heading01',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
    >
      No image
    </Box>
  </Box>
)
const TextBox = styledBox({
  display: (props) => [
    props.oneLineTextOnMobile ? 'grid' : 'block',
    props.oneLineTextOnMobile ? 'grid' : 'block',
    props.theme === 'default' ? 'grid' : 'flex',
    props.theme === 'default' ? 'grid' : 'flex',
    props.theme === 'default' ? 'grid' : 'flex'
  ],
  gridTemplateColumns: (props) => (props.theme === 'default' ? 'auto auto' : 'initial')
})
const Title = styledBox({ font: 'heading06', pr: (props) => (props.theme === 'default' ? 's5' : 's8') })
const PriceBox = styledBox({
  font: 'heading01',
  color: 'mono600',
  s: { mr: 's4' },
  whiteSpace: 'nowrap',
  minWidth: 'auto',
  textAlign: ['left', 'left', 'right']
})

const ImageWrapper = styledBox({ bg: '#F5F2ED' })
const Tags = styledBox({ font: 'heading01' })
const ProductCard = ({
  product,
  hasLeftIndent,
  hasRightIndent,
  oneLineTextOnMobile,
  withSizesBox = true,
  theme = 'default'
}) => {
  if (!product) {
    return null
  }
  let _leftIndent = null
  let _rightIndent = null

  let _sizes = putImageSizes([
    oneLineTextOnMobile ? '100vw' : '50vw',
    oneLineTextOnMobile ? '100vw' : '50vw',
    '50vw',
    '33vw',
    '25vw',
    '25vw'
  ])

  if (hasLeftIndent) {
    _leftIndent = [
      hasLeftIndent[0] ? 's7' : 0,
      hasLeftIndent[1] ? 's7' : 0,
      hasLeftIndent[2] ? 's7' : 0,
      hasLeftIndent[3] ? 's7' : 0,
      hasLeftIndent[4] ? 's7' : 0
    ]
  }
  if (hasRightIndent) {
    _rightIndent = [
      hasRightIndent[0] ? 's7' : 0,
      hasRightIndent[1] ? 's7' : 0,
      hasRightIndent[2] ? 's7' : 0,
      hasRightIndent[3] ? 's7' : 0,
      hasRightIndent[4] ? 's7' : 0
    ]
  }

  let primaryMedia = product.primaryMedia ? product.primaryMedia : product.primaryImage
  let secondaryMedia = product.secondaryMedia ? product.secondaryMedia : product.secondaryImage

  let isPreorder = product.tags.includes('preorder')

  let isComingSoon = product.tags.includes('coming-soon')

  //
  // console.log(
  //   str,
  //   str.slice(0, str.indexOf("-")) - 1,
  //   str.slice(str.indexOf("-") + 1) - 1
  // );

  return (
    <div>
      <Button href={'/product/' + product.handle} sx={{ display: 'block', width: '100%' }}>
        {({ hovered, isHovered }) => {
          return (
            <>
              <ImageRoot>
                {primaryMedia ? (
                  primaryMedia.type === 'VIDEO' ? (
                    <Video video={primaryMedia} />
                  ) : (
                    <ImageWrapper>
                      <Image image={primaryMedia} sizes={_sizes} />
                    </ImageWrapper>
                  )
                ) : (
                  noImageElem
                )}
                {secondaryMedia && (
                  <ImageAlternateBox hovered={hovered}>
                    {secondaryMedia.type === 'VIDEO' ? (
                      <Video video={secondaryMedia} />
                    ) : (
                      <Image image={secondaryMedia} sizes={_sizes} />
                    )}
                  </ImageAlternateBox>
                )}
                {isComingSoon ||
                  (isPreorder && (
                    <TagOverlay>
                      <TagInner>
                        <TagText
                          dangerouslySetInnerHTML={{ __html: isComingSoon ? 'Coming Soon' : isPreorder && 'Preorder' }}
                        />
                      </TagInner>
                    </TagOverlay>
                  ))}
              </ImageRoot>
              <BottomBox
                theme={theme}
                oneLineTextOnMobile={oneLineTextOnMobile}
                leftIndent={_leftIndent}
                rightIndent={_rightIndent}
              >
                <TextBox theme={theme}>
                  <Title theme={theme}>{product.title}</Title>
                  <PriceBox>
                    <ProductPrice product={product} />
                  </PriceBox>
                </TextBox>
                {/*<Tags>*/}
                {/*{product.tags.map(t => <div>{t}</div>)}*/}
                {/*</Tags>*/}
                {withSizesBox && (
                  <SizesBox hovered={hovered}>
                    {!isComingSoon && product.variants.filter((v) => v.available === true).length === 0
                      ? 'Sold out'
                      : product.variants.length > 1
                      ? product.variants.map((size) => {
                          if (size.available) {
                            return (
                              <SizeBox key={size.id} available={size.available}>
                                {size.title}{' '}
                              </SizeBox>
                            )
                          } else {
                            return null
                          }
                        })
                      : 'One size'}
                  </SizesBox>
                )}
              </BottomBox>
            </>
          )
        }}
      </Button>
    </div>
  )
}

export default ProductCard
