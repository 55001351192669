import Icon from "@commerce-ui/core2/Icon";
import React from "react";


const IconChevron = (props) => {
  return <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.66 4.25"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon points="3.33 4.25 0 0.68 0.73 0 3.33 2.79 5.93 0 6.66 0.68 3.33 4.25"/></g></g></svg>
  </Icon>
};

export default IconChevron