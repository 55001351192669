import React, { useState, useEffect, useRef } from 'react'
import Box from '@commerce-ui/core2/Box'
import { lin } from '@commerce-ui/core2'
import Container from '@commerce-ui/core2/Container'
import Logo from '../Logo'
import IconSearch from '../icons/IconSearch'
import IconBurger from '../icons/IconBurger'
import Dialog from '@commerce-ui/core2/Dialog'
import ButtonHeaderBar from '../buttons/ButtonHeaderBar'
import useCart from '@data/useCart'
import putLineItemsQuantity from '../../helpers/putLineItemsQuantity'
import MenuLayout from '@commerce-ui/core2/MenuLayout'
import HeaderShopOverlay from './HeaderShopOverlay'
import HeaderCollectionsOverlay from './HeaderCollectionsOverlay'
import HeaderAssistanceOverlay from './HeaderAssistanceOverlay'
import HeaderCountrySelectionOverlay from './HeaderCountrySelectionOverlay'
import Image from '@image'
import styledBox from '../../styledBox'
import IconClose from '../icons/IconClose'
import IconArrow from '../icons/IconArrow'
import HeaderMobileShopOverlay from './HeaderMobileShopOverlay'
import ButtonBag from './ButtonBag'
import { useRouter } from 'next/router'
import SearchOverlay from '../SearchOverlay'

const MOBILE_DIALOG_STATES = ['HIDDEN', 'SHOP', 'COUNTRIES', 'ASSISTANCE']

const Nav = styledBox({
  display: (props) => [
    props.mobile ? 'grid' : 'none',
    props.mobile ? 'grid' : 'none',
    props.mobile ? 'grid' : 'none',
    !props.mobile ? 'grid' : 'none',
    !props.mobile ? 'grid' : 'none'
  ],
  gridAutoColumns: 'min-content',
  gridAutoFlow: 'column',
  gridGap: lin('s5', 's8'),
  justifySelf: (props) => (props.snapToRight ? 'end' : 'start'),
  zIndex: 1
})

const MenuContentBox = styledBox({
  width: '100%',
  height: '85vh',
  minHeight: '640px',
  bg: 'black',
  transition: (props) => (props.isVisible ? `opacity .15s linear` : `opacity .15s linear .15s`),
  opacity: (props) => (props.isVisible ? 1 : 0),
  overflow: 'hidden',
  zIndex: 5
})
const MenuContentImage = styledBox(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  { fitW: true, fitH: true }
)
const MenuContentOverlay = styledBox({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  py: 'headerBarHeight',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
const LogoBox = styledBox({
  width: 'headerLogoWidth',
  height: 'headerLogoHeight',
  display: 'flex',
  alignItems: 'center'
})
const WrapperAboveOverlay = styledBox({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: 'headerBarHeight',
  display: 'flex',
  justifyContent: 'center',
  color: 'white'
})
const _space = lin('s5', 's8')

const Bar = styledBox({
  py: _space,
  height: 'headerBarHeight',
  display: 'grid',
  gridTemplateColumns: ['1fr auto 1fr', '1fr auto 1fr', '1fr auto 1fr', '1fr auto 1fr', '1fr auto 1fr'],
  alignItems: 'center',
  color: (props) => (props.isForcedWhite ? 'white !important' : props.isWhite ? 'white' : 'black'),
  transition: 'color 100ms'
})
const LogoWrapper = styledBox({
  color: 'inherit',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: ['1', null, null, '1']
})

const DialogFooter = styledBox({
  bg: 'rgba(0,0,0,0.5)',
  backdropFilter: 'blur(50px)',
  position: 'relative',
  borderTop: '1px solid',
  borderColor: 'rgba(255,255,255,0.15)'
})
const DialogFooterInner = styledBox({
  height: 'headerBarHeight',
  width: '100%',
  alignItems: 'center',
  display: 'grid',
  gridAutoColumns: 'min-content',
  gridAutoFlow: 'column',
  gridGap: 's9',
  color: 'mono500'
})

const DialogBackground = styledBox(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  { fitW: true, fitH: true }
)

const DialogBackgroundGradient = styledBox({
  background: 'linear-gradient(0deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '130px',
  zIndex: 2
})

const DialogHeader = styledBox({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 3
})
const DialogHeaderInner = styledBox({
  height: 'headerBarHeight',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  justifyContent: 'space-between'
})

const GradientStyled = styledBox({
  background: 'linear-gradient( 0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)',
  pointerEvents: 'none',
  transition: 'opacity 300ms',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '50vh',
  zIndex: -1,
  opacity: (props) => (props.isHidden ? 0 : null)
})

const DialogBody = styledBox({ mb: 's8', position: 'relative' })

const LogoInBar = ({ id }) => (
  <LogoWrapper id={id}>
    <ButtonHeaderBar href={'/'}>
      <LogoBox>
        <Logo />
      </LogoBox>
    </ButtonHeaderBar>
  </LogoWrapper>
)

function MenuContentContainer({ isVisible, image, children }) {
  return (
    <MenuContentBox isVisible={isVisible}>
      <WrapperAboveOverlay>
        <LogoInBar />
      </WrapperAboveOverlay>
      <MenuContentImage>
        <Image sizes={'100vw'} image={image} ignoreBottomPadding />
      </MenuContentImage>
      <MenuContentOverlay>{children}</MenuContentOverlay>
    </MenuContentBox>
  )
}

const HeaderBar = ({ data, onBagClick, mode }) => {
  if (!data) return null

  const cart = useCart()

  const [mobileOverlayState, setMobileOverlayState] = useState(MOBILE_DIALOG_STATES[0])

  let productsInBagQuantity = cart ? putLineItemsQuantity(cart.lineItems) : null

  const router = useRouter()
  // console.log(router)

  let isWhite
  let isForcedWhite
  const scrolled = useRef(false)

  useEffect(() => {
    let windowH = window.innerHeight
    let windowW = window.innerWidth

    document.getElementById('header-bar').style.color = null
    document.getElementById('header-gradient').style.opacity = null

    setTimeout(() => (document.getElementById('SmallLogo').style.transition = `opacity 300ms`), 50)

    const handleResize = () => {
      windowH = window.innerHeight
      windowW = window.innerWidth
    }

    const handleScroll = () => {
      let windowY = window.scrollY

      if (windowY > windowH && !scrolled.current) {
        scrolled.current = true

        if (mode === 'WHITE_TO_BLACK') {
          document.getElementById('header-gradient').style.opacity = 0
        }
        if (mode === 'WHITE_TO_BLACK') {
          document.getElementById('header-bar').style.color = 'black'
        }
      }
      if (windowY <= windowH && scrolled.current) {
        scrolled.current = false
        if (mode === 'WHITE_TO_BLACK') {
          document.getElementById('header-gradient').style.opacity = 1
        }
        if (mode === 'WHITE_TO_BLACK') {
          document.getElementById('header-bar').style.color = 'white'
        }
      }
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [mode])

  const menu = MenuLayout.useDialogs({
    animationTimeout: 400,
    positionedRelativeToTop: true,
    backgroundOverlay: {
      opacity: 0.3
    },
    items: [
      {
        button: <ButtonHeaderBar>Shop</ButtonHeaderBar>,
        content: ({ isVisible }) => (
          <MenuContentContainer image={data.headerBackgrounds.categories} isVisible={isVisible}>
            <HeaderShopOverlay {...data} />
          </MenuContentContainer>
        ),
        anchoredTo: 'window',
        width: '100%'
      },
      {
        button: <ButtonHeaderBar>Stories</ButtonHeaderBar>,
        content: ({ isVisible }) => (
          <MenuContentContainer image={data.headerBackgrounds.stories} isVisible={isVisible}>
            <HeaderCollectionsOverlay links={data.stories} viewAllHref={'/stories'} />
          </MenuContentContainer>
        ),
        anchoredTo: 'window',
        width: '100%'
      },
      {
        button: <ButtonHeaderBar>Collections</ButtonHeaderBar>,
        content: ({ isVisible }) => (
          <MenuContentContainer image={data.headerBackgrounds.collections} isVisible={isVisible}>
            <HeaderCollectionsOverlay links={data.collections} viewAllHref={'/collections'} />
          </MenuContentContainer>
        ),
        anchoredTo: 'window',
        width: '100%'
      },
      {
        button: (
          <ButtonHeaderBar>
            {router.query.lang === 'pl' && 'PLN / EN'}
            {router.query.lang === 'us' && 'USD / EN'}
            {router.query.lang === 'eu' && 'EUR / EN'}
            {router.query.lang === 'uk' && 'GBP / EN'}
          </ButtonHeaderBar>
        ),
        content: ({ isVisible }) => (
          <MenuContentContainer image={data.headerBackgrounds.countries} isVisible={isVisible}>
            <HeaderCountrySelectionOverlay {...data} />
          </MenuContentContainer>
        ),
        anchoredTo: 'window',
        width: '100%'
      },
      {
        button: <ButtonHeaderBar>Assistance</ButtonHeaderBar>,
        content: ({ isVisible }) => (
          <MenuContentContainer image={data.headerBackgrounds.assistance} isVisible={isVisible}>
            <HeaderAssistanceOverlay {...data} />
          </MenuContentContainer>
        ),
        anchoredTo: 'window',
        width: '100%'
      }
    ]
  })

  isWhite = mode !== 'BLACK'

  isForcedWhite = menu.isOpen

  const showSale = false
  const showBags = true

  return (
    <>
      <GradientStyled id={'header-gradient'} isHidden={mode === 'BLACK'} />
      {/*<Container style={{pointerEvents: 'none'}}>*/}
      <Container>
        <Bar isWhite={isWhite} isForcedWhite={isForcedWhite} id={'header-bar'}>
          <Nav mobile>
            <ButtonHeaderBar onClick={() => setMobileOverlayState(MOBILE_DIALOG_STATES[1])}>
              <IconBurger size={24} />
            </ButtonHeaderBar>
          </Nav>
          <Nav>
            {menu.buttons[0]}
            {menu.buttons[1]}
            {menu.buttons[2]}
            {showBags && <ButtonHeaderBar href={'/handbags'}>Handbags</ButtonHeaderBar>}
            {showSale && <ButtonHeaderBar href={'/category/sale'}>Sale</ButtonHeaderBar>}
            <Dialog
              width={'100%'}
              height={'100%'}
              button={
                <ButtonHeaderBar>
                  <IconSearch size={24} />
                </ButtonHeaderBar>
              }
              anchoredTo={'window'}
            >
              {({ close }) => <SearchOverlay close={close} />}
            </Dialog>
          </Nav>
          <div style={{ zIndex: 0 }}>
            <LogoInBar id={'SmallLogo'} />
          </div>
          <Nav mobile snapToRight>
            <ButtonBag quantity={productsInBagQuantity} isWhite={isWhite} onClick={onBagClick} />
          </Nav>
          <Nav snapToRight>
            {menu.buttons[3]}
            {menu.buttons[4]}
            <ButtonBag quantity={productsInBagQuantity} isWhite={isWhite} onClick={onBagClick} />
          </Nav>
        </Bar>
      </Container>
      <Dialog
        width={'100%'}
        height={'100%'}
        isOpen={mobileOverlayState !== MOBILE_DIALOG_STATES[0]}
        onClose={() => {
          setMobileOverlayState(MOBILE_DIALOG_STATES[0])
        }}
        root={{
          bg: 'black'
        }}
        footer={
          <DialogFooter>
            <Container>
              <DialogFooterInner>
                <ButtonHeaderBar
                  onClick={() => setMobileOverlayState(MOBILE_DIALOG_STATES[2])}
                  underlined={mobileOverlayState === MOBILE_DIALOG_STATES[2]}
                >
                  {router.query.lang === 'pl' && 'PLN / EN'}
                  {router.query.lang === 'us' && 'USD / EN'}
                  {router.query.lang === 'eu' && 'EUR / EN'}
                  {router.query.lang === 'uk' && 'GBP / EN'}
                </ButtonHeaderBar>
                <ButtonHeaderBar
                  onClick={() => setMobileOverlayState(MOBILE_DIALOG_STATES[3])}
                  underlined={mobileOverlayState === MOBILE_DIALOG_STATES[3]}
                >
                  Assistance
                </ButtonHeaderBar>
              </DialogFooterInner>
            </Container>
          </DialogFooter>
        }
      >
        <DialogBackground>
          <Image image={data.headerBackgrounds.mobile} sizes={'100vw'} aspectRatio={'portrait'} />
        </DialogBackground>

        <DialogBackgroundGradient />

        <DialogHeader>
          <Container>
            <DialogHeaderInner>
              <ButtonHeaderBar onClick={() => setMobileOverlayState(MOBILE_DIALOG_STATES[0])}>
                <IconClose size={24} />
              </ButtonHeaderBar>
              <Dialog
                width={'100%'}
                height={'100%'}
                button={
                  <ButtonHeaderBar>
                    <IconSearch size={32} />
                  </ButtonHeaderBar>
                }
                anchoredTo={'window'}
              >
                {({ close }) => <SearchOverlay close={close} />}
              </Dialog>
            </DialogHeaderInner>
          </Container>
        </DialogHeader>

        <DialogBody>
          <Container>
            {[
              {
                component: <HeaderMobileShopOverlay {...data} showSale={showSale} showBags={showBags} />,
                visibleState: MOBILE_DIALOG_STATES[1]
              },
              {
                component: <HeaderCountrySelectionOverlay {...data} />,
                visibleState: MOBILE_DIALOG_STATES[2]
              },
              {
                component: <HeaderAssistanceOverlay {...data} />,
                visibleState: MOBILE_DIALOG_STATES[3]
              }
            ].map((item, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: mobileOverlayState === item.visibleState ? 'block' : 'none'
                  }}
                >
                  {item.visibleState !== MOBILE_DIALOG_STATES[1] && (
                    <Box
                      sx={{
                        pt: 's12',
                        pb: 's10',
                        color: 'white'
                      }}
                    >
                      <ButtonHeaderBar onClick={() => setMobileOverlayState(MOBILE_DIALOG_STATES[1])}>
                        <IconArrow size={24} />
                      </ButtonHeaderBar>
                    </Box>
                  )}
                  {item.component}
                </Box>
              )
            })}
          </Container>
        </DialogBody>
      </Dialog>
    </>
  )
}

export default HeaderBar
export { LogoInBar }
