import React, { useState, useEffect, useRef } from 'react'
import theme from '../theme'
import styled from '@emotion/styled'
import IconBag from '../icons/IconBag'

const ShopButtonContainer = styled.button(
  {
    cursor: 'pointer',
    textDecoration: 'none',
    border: 'none',
    display: 'inline-flex',
    height: '42px',
    whiteSpace: 'nowrap',
    background: 'none',
    appearance: 'none',
    boxSizing: 'border-box',
    padding: 0,
    width: 'auto',
    maxWidth: '42px',
    background: 'white',
    borderRadius: '42px',
    overflow: 'hidden',
    transition: 'max-width 300ms ease-in-out'
  },
  (props) =>
    props.isHovered === true && {
      maxWidth: props.standardWidth
    }
)

const Icon = styled.div({
  height: '42px',
  width: '42px',
  minWidth: '42px',
  borderRadius: '50%',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    width: '12px'
  }
})

const Label = styled.span(
  {
    ...theme.typography.heading01,
    color: 'black',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '16px',
    borderRadius: '21px',
    opacity: 0,
    transition: 'opacity 300ms'
  },
  (props) =>
    props.isHovered === true && {
      opacity: 1
    }
)

const ShopNowButton = React.forwardRef(({ label, ...restProps }, ref) => {
  const [isHovered, setIsHovered] = useState(false)
  const [maxWidth, setMaxWidth] = useState('42px')
  const refLabel = useRef(null)

  useEffect(() => {
    setMaxWidth(refLabel.current.offsetWidth + 42 + 'px')
    const handleResize = () => {
      setMaxWidth(refLabel.current.offsetWidth + 42 + 'px')
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ShopButtonContainer
      {...restProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
      standardWidth={maxWidth}
    >
      <Icon>
        <IconBag />
      </Icon>
      {label && (
        <Label ref={refLabel} isHovered={isHovered}>
          {label}
        </Label>
      )}
    </ShopButtonContainer>
  )
})

export default ShopNowButton
