import Icon from "@commerce-ui/core2/Icon";
import React from "react";


const IconBurger = (props) => {
  return <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><g><rect width="24" height="24" fill="none"/><rect y="7.5" width="24" height="1"/><rect y="15.5" width="24" height="1"/></g></g></svg>
  </Icon>
};

export default IconBurger