import Button from "@button";
import React from "react";

// const ButtonText = (props) => {
//   return <Button
//     {...props}
//     sx={
//       ({hovered}) => ({
//         whiteSpace: 'nowrap',
//         font: 'heading01',
//         color: hovered ? 'mono600' : 'black',
//         transition: 'color 100ms'
//       })
//     }
//   />
// };

const ButtonText = Button.styled({
    sx: {
        whiteSpace: 'nowrap',
        font: 'heading01',
        color: p => p.hovered ? 'mono600' : 'black',
        transition: 'color 100ms'
    }
});

export default ButtonText
