export const LANGS = ["pl", "eu", "uk", "us"];
export const CURRENCIES = ["PLN", "EUR", "GBP", "USD"];

export const CURRENCY_LANG_MAP = {
    [CURRENCIES[0]]: LANGS[0],
    [CURRENCIES[1]]: LANGS[1],
    [CURRENCIES[2]]: LANGS[2],
    [CURRENCIES[3]]: LANGS[3],
};

