import Icon from '@commerce-ui/core2/Icon'
import React from 'react'

const IconBag = (props) => {
  return (
    <Icon {...props}>
      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00011 0.315369C7.90002 0.315369 9.45308 2.52362 9.56174 5.30829H10.5304L11.1409 13.6846H0.859375L1.50273 5.30829H2.43849C2.54715 2.52362 4.10019 0.315369 6.00011 0.315369ZM3.43935 5.30829H8.56088C8.50908 4.1463 8.17116 3.13812 7.69305 2.42719C7.16843 1.64716 6.55099 1.31537 6.00011 1.31537C5.44922 1.31537 4.8318 1.64716 4.30717 2.42719C3.82906 3.13812 3.49115 4.1463 3.43935 5.30829ZM1.93913 12.6846L2.42887 6.30829H9.6006L10.0653 12.6846H1.93913Z"
          fill="black"
        />
      </svg>
    </Icon>
  )
}

export default IconBag
