import React from 'react'
import Box from "@commerce-ui/core2/Box";
import {lin} from "@commerce-ui/core2";
import Link from "@link";
import styledBox from "../../styledBox";


const Root = styledBox({
  display: 'grid',
  gridTemplateColumns: [
    null,
    null,
    '180px 180px',
  ],
  gridColumnGap: lin('s11','s12'),
  gridRowGap: lin('s8','s9'),
  alignItems: 'start',
  color: 'white',
})
const TextWrapper = styledBox({
  "h3": {
    font: 'heading02', mb: '1em', fontWeight: 400
  },
  "p" : {
    m: '0',
    mb: '1em',
    font: 'heading01',
    "em" : {
      font: 'heading09'
    },
    "a" : {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  "ul, li" : {
    p: 0, m: 0, font: 'heading01',
    listStyle: 'none'
  }
});


const HeaderAssistanceOverlay = ({data}) => {

  return <Root>

      <TextWrapper>
        <h3>Customer Service</h3>
        <p>
          Email <br/>
          <a href="mailto:customercare@magdabutrym.com">customercare@magdabutrym.com</a><br />
          {/*<a href="">Call / Text +48 607 488 828</a><br />*/}
          {/*<a href={""}>Live Chat <em>(Online)</em></a>*/}
        </p>
        <p>
          Operating Hours<br />
          Monday - Friday<br />
          9 am - 6 pm CET
        </p>
      </TextWrapper>
      <TextWrapper>
        <h3>Information</h3>

        <ul>
        {
          [
            {
              label: 'Shipping',
              href: '/shipping'
            },
            {
              label: 'Returns and Exchanges',
              href: '/returns-and-exchanges'
            },
            {
              label: 'Customer Service',
              href: '/customer-service'
            }
          ].map((item, i) => <li key={i} ><Link href={item.href}>{item.label}</Link></li>)
        }
        </ul>

      </TextWrapper>

    </Root>
};

export default HeaderAssistanceOverlay
