import {productImages} from "./images";

// Product:
//   - description
//   - descriptionHTML
//   - title
//   - handle
//   - id
//   - images
//   - color ({ name, hex })
//   - tags ([string])
//   - variants ([ProductVariant])
//   - sizes: ([ { id, title } ])
//   - relatedProducts ([Product]) -> inne warianty kolorystyczne tego produktu
//   - price (Price)
//   - compareAtPrice (Price) -> normalna cena, gdy mamy przecenę

// ProductVariant
//   - available
//   - product (Product)
//   - size ({ id, title })
//   - sku
//   - isLowStock (boolean)
//   - isFinalSale (boolean)

// Price
//   - currencyCode ("USD" / "PLN" / "EUR" / "GBP")
//   - amount (string "10.99")


const SIZING_CHART_CM = [
    {
        title: 'Size',
        columns: ['34', '36', '38', '40', '42']
    },
    {
        title: 'Bust (CM)',
        columns: ['83', '86', '89', '92', '100']
    },
    {
        title: 'Waist (CM)',
        columns: ['63', '66', '69', '72', '75']
    },
    {
        title: 'Hips (CM)',
        columns: ['89', '92', '98', '101', '103']
    }
]
const SIZING_CHART_IN = [
    {
        title: 'Size',
        columns: ['34', '36', '38', '40', '42']
    },
    {
        title: 'Bust (IN)',
        columns: ['32.3', '33.9', '35.4', '37', '100']
    },
    {
        title: 'Waist (IN)',
        columns: ['63', '66', '69', '72', '75']
    },
    {
        title: 'Hips (IN)',
        columns: ['89', '92', '98', '101', '103']
    }
]
const SIZE_CONVERSION_CHART = [
    {
        title: 'Magda Butrym',
        columns: ['34', '36', '38', '40', '42', '44', '46', '48', '50', '52', '54']
    },
    {
        title: 'France (FR)',
        columns: ['34', '36', '38', '40', '42', '44', '46', '48', '50', '52', '54']
    },
    {
        title: 'Italy (IT)',
        columns: ['38', '40', '42', '44', '46', '44', '46', '48', '50', '52', '54']
    },
    {
        title: 'UK (UK)',
        columns: ['4', '6', '8', '10', '12', '14', '16', '17', '50', '52', '54']
    },
    {
        title: 'USA (US)',
        columns: ['2', '4', '6', '8', '10', '44', '46', '48', '50', '52', '54']
    },
    {
        title: 'Ukraine (UA)',
        columns: ['38', '40', '42', '44', '46', '44', '46', '48', '50', '52', '54']
    },
    {
        title: 'Australia (AU)',
        columns: ['6', '36', '36', '36', '36', '44', '46', '48', '50', '52', '54']
    }
]

const SIZES_SAMPLE_LONG = [
    {id: "22", label: "22"},
    {id: "24", label: "24"},
    {id: "26", label: "26"},
    {id: "28", label: "28"},
    {id: "30", label: "30"},
    {id: "32", label: "32"},
    {id: "34", label: "34"},
    {id: "36", label: "36.66"},
    {id: "38", label: "38"},
    {id: "40", label: "40"},
    {id: "42", label: "42"},
    {id: "44", label: "44"},
    {id: "46", label: "46"},
    {id: "48", label: "48"},
    {id: "50", label: "50"},
];
const SIZES_SAMPLE_SHORT = [
    {id: "24", label: "24"},
    {id: "26", label: "26"},
    {id: "28", label: "28"},
];
const PRODUCT_PRICE_SAMPLE = {
    currencyCode: "USD",
    amount: "4250"
};

const priceUsd = (x) => {
    return {
        currencyCode: "USD",
        amount: x.toString()
    }
}

const PRODUCT_PRICE_SAMPLE_2 = {
    currencyCode: "USD",
    amount: "3150"
};

let sku = 0;

const makeVariant = (size, id) => {
    sku++;
    return {
        available: sku % 3 !== 1,
        size: size,
        isLowStock: sku % 2 === 1,
        isFinalSale: sku % 3 === 0,
        quantityAvailable: 4,
        sku: sku,
        productId: id
    }
};

let productVideo = {
    src: [
        {
            url: "/video_home.mp4",
            w: 1280,
            h: 720,
            type: "video/mp4"
        }
    ],
    alt: "Magda Butrym Video",
}

let PRODUCT_00001 = {
    id: "PRODUCT_00001",
    title: "Warsaw Dress",
    description: ``,
    descriptionHTML: ``,
    color: {name: "White", hex: "#FFFFFF"},
    tags: ["Collaboration"],
    sizes: SIZES_SAMPLE_LONG,
    images: [productImages[0], productImages[9]],
    handle: "warsaw-dress-white",
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    price: priceUsd(4250),
    compareAtPrice: null,
};
PRODUCT_00001.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00001.id));


let PRODUCT_00002 = {
    id: "PRODUCT_00002",
    title: "Lorem Ipsum Dress",
    description: ``,
    descriptionHTML: ``,
    color: {name: "Grey", hex: "#BBBBBB"},
    tags: [],
    sizes: SIZES_SAMPLE_SHORT,
    images: [productImages[1], productImages[8]],
    handle: "warsaw-dress-grey",
    price: priceUsd(1000),
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    compareAtPrice: null
};
PRODUCT_00002.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00002.id));


let PRODUCT_00003 = {
    id: "PRODUCT_00003",
    title: "Warsaw Dress",
    description: ``,
    detailsHTML: `
    <p>Midi dress in ivory with plissé detailing. Featuring spaghetti shoulder straps. Silk lining.</p>
    <ul>
        <li>89% Viscose</li>
        <li>10% Polyamide</li>
        <li>1% Elastane</li>
        <li>Made in Poland</li>
    </ul>
  `,
    sizingHTML: `
  <ul>
    <li>This piece fits true to size. We recommend you get your regular size. </li>
    <li>Designed for a close fit at the bust and waist, but loose at the hip.</li>
    <li>Model is 180 cm and is wearing a size 36</li>
  </ul>
  `,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    descriptionHTML: `<p>Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.</p>`,
    color: {name: "Black", hex: "#000000"},
    tags: ["New Arrival"],
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizes: SIZES_SAMPLE_LONG,
    images: [productVideo, productImages[2], productImages[10], productImages[11]],
    handle: "warsaw-dress-black",
    price: priceUsd(3000),
    compareAtPrice: null
};
PRODUCT_00003.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00003.id));


let PRODUCT_00004 = {
    id: "PRODUCT_00004",
    title: "Belgrade Dress",
    descriptionHTML: `<p>Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.</p>`,
    detailsHTML: `
    <p>Midi dress in ivory with plissé detailing. Featuring spaghetti shoulder straps. Silk lining.</p>
    <ul>
        <li>89% Viscose</li>
        <li>10% Polyamide</li>
        <li>1% Elastane</li>
        <li>Made in Poland</li>
    </ul>
  `,
    sizingHTML: `
  <ul>
    <li>This piece fits true to size. We recommend you get your regular size. </li>
    <li>Designed for a close fit at the bust and waist, but loose at the hip.</li>
    <li>Model is 180 cm and is wearing a size 36</li>
  </ul>
  `,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    color: {name: "Black", hex: "#000000"},
    tags: ["Collaboration"],
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizes: SIZES_SAMPLE_SHORT,
    images: [productImages[3], productImages[6]],
    handle: "belgrade-dress-black",
    price: priceUsd(4000),
    compareAtPrice: PRODUCT_PRICE_SAMPLE_2
};
PRODUCT_00004.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00004.id));


let PRODUCT_00005 = {
    id: "PRODUCT_00005",
    title: "Belgrade Dress",
    description: `Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.`,
    descriptionHTML: ``,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    color: {name: "White", hex: "#FFFFFF"},
    tags: [],
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizes: SIZES_SAMPLE_LONG,
    images: [productImages[4], productImages[5]],
    handle: "belgrade-dress-white",
    price: PRODUCT_PRICE_SAMPLE,
    compareAtPrice: null
};
PRODUCT_00005.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00005.id));


let PRODUCT_00006 = {
    id: "PRODUCT_00006",
    title: "Belgrade Dress",
    description: `Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.`,
    descriptionHTML: ``,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    color: {name: "Grey", hex: "#888888"},
    tags: ["New Arrival"],
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizes: SIZES_SAMPLE_LONG,
    images: [productImages[4], productImages[5]],
    handle: "belgrade-dress-grey",
    price: PRODUCT_PRICE_SAMPLE,
    compareAtPrice: null
};
PRODUCT_00006.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00006.id));


let PRODUCT_00007 = {
    id: "PRODUCT_00007",
    title: "Belgrade Dress",
    description: `Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.`,
    descriptionHTML: ``,
    color: {name: "Red", hex: "#c20000"},
    tags: ["Very very very very long tag"],
    sizes: SIZES_SAMPLE_LONG,
    images: [productImages[6], productImages[3]],
    handle: "belgrade-dress-red",
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    price: PRODUCT_PRICE_SAMPLE,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    compareAtPrice: null
};
PRODUCT_00007.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00007.id));


let PRODUCT_00008 = {
    id: "PRODUCT_00008",
    title: "Belgrade Dress",
    description: `Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.`,
    descriptionHTML: ``,
    color: {name: "Green", hex: "#608F40"},
    tags: [],
    sizes: SIZES_SAMPLE_SHORT,
    images: [productImages[7], productImages[2]],
    handle: "belgrade-dress-green",
    price: PRODUCT_PRICE_SAMPLE,
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    compareAtPrice: null
};
PRODUCT_00008.variants = SIZES_SAMPLE_LONG.map((size, i) => makeVariant(size, PRODUCT_00008.id));


let PRODUCT_00009 = {
    id: "PRODUCT_00009",
    title: "Belgrade Dress",
    descriptionHTML: `<p>Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.</p>`,
    detailsHTML: `
    <p>Midi dress in ivory with plissé detailing. Featuring spaghetti shoulder straps. Silk lining.</p>
    <ul>
        <li>89% Viscose</li>
        <li>10% Polyamide</li>
        <li>1% Elastane</li>
        <li>Made in Poland</li>
    </ul>
  `,
      sizingHTML: `
  <ul>
    <li>This piece fits true to size. We recommend you get your regular size. </li>
    <li>Designed for a close fit at the bust and waist, but loose at the hip.</li>
    <li>Model is 180 cm and is wearing a size 36</li>
  </ul>
  `,
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    color: {name: "Sand", hex: "#cF9F00"},
    tags: [],
    sizes: null,
    images: [productImages[8], productImages[1]],
    handle: "belgrade-dress-sand",
    price: PRODUCT_PRICE_SAMPLE,
    compareAtPrice: null
};
PRODUCT_00009.variants = [makeVariant(null, PRODUCT_00009.id)];




let PRODUCT_00010={
    id: "PRODUCT_00010",
    title: "Belgrade Dress",
        descriptionHTML: `<p>Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.</p>`,
    detailsHTML: `
    <p>Midi dress in ivory with plissé detailing. Featuring spaghetti shoulder straps. Silk lining.</p>
    <ul>
        <li>89% Viscose</li>
        <li>10% Polyamide</li>
        <li>1% Elastane</li>
        <li>Made in Poland</li>
    </ul>
  `,
    sizingHTML: `
  <ul>
    <li>This piece fits true to size. We recommend you get your regular size. </li>
    <li>Designed for a close fit at the bust and waist, but loose at the hip.</li>
    <li>Model is 180 cm and is wearing a size 36</li>
  </ul>
  `,
    sizingChart: SIZING_CHART_CM,
    sizingChartImperial: SIZING_CHART_IN,
    sizingConversionChart: SIZE_CONVERSION_CHART,
    color: {name: "Blue", hex: "#2b2f98"},
    tags: [],
    sizes: null,

    images: [productImages[9], productImages[0]],
    handle: "belgrade-dress-blue",
    price: PRODUCT_PRICE_SAMPLE,
    compareAtPrice: null
};
PRODUCT_00010.variants = [makeVariant(null, PRODUCT_00010.id)];

// =======
//     let PRODUCT_00010_VARIANTS = [makeVariant(null)];
// let PRODUCT_00010 = {
//     id: "PRODUCT_00010",
//     title: "Belgrade Dress",
//     descriptionHTML: `<p>Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.</p>`,
//     detailsHTML: `
//     <p>Midi dress in ivory with plissé detailing. Featuring spaghetti shoulder straps. Silk lining.</p>
//     <ul>
//         <li>89% Viscose</li>
//         <li>10% Polyamide</li>
//         <li>1% Elastane</li>
//         <li>Made in Poland</li>
//     </ul>
//   `,
//     sizingHTML: `
//   <ul>
//     <li>This piece fits true to size. We recommend you get your regular size. </li>
//     <li>Designed for a close fit at the bust and waist, but loose at the hip.</li>
//     <li>Model is 180 cm and is wearing a size 36</li>
//   </ul>
//   `,
//     description: `Fitted asymetrical dress with signature floral print. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis maximus magna.`,
//     color: {name: "Sand", hex: "#cF9F00"},
//     tags: ["One size, no color options"],
//     sizes: null,
//
//     relatedProducts: null,
//     images: [productImages[9], productImages[0]],
//     handle: "belgrade-dress-blue",
//     price: PRODUCT_PRICE_SAMPLE,
//     compareAtPrice: null
// };
// PRODUCT_00010.variants = PRODUCT_00010_VARIANTS;
// PRODUCT_00010_VARIANTS.map((variant, i)=> {
//     variant.product = PRODUCT_00010;
//     return variant
// });
//
// PRODUCT_00001.relatedProducts = [PRODUCT_00001];
// PRODUCT_00002.relatedProducts = [PRODUCT_00002];
// PRODUCT_00003.relatedProducts = [PRODUCT_00001, PRODUCT_00002, PRODUCT_00003];
// PRODUCT_00004.relatedProducts = [PRODUCT_00004];
// PRODUCT_00005.relatedProducts = [PRODUCT_00005];
// PRODUCT_00006.relatedProducts = [PRODUCT_00006];
// PRODUCT_00007.relatedProducts = [PRODUCT_00007];
// PRODUCT_00008.relatedProducts = [PRODUCT_00009, PRODUCT_00008];
// PRODUCT_00009.relatedProducts = [PRODUCT_00009, PRODUCT_00008];
// PRODUCT_00010.relatedProducts = [PRODUCT_00010];
//
//
// const products = [
//         PRODUCT_00001,
//         PRODUCT_00002,
//         PRODUCT_00003,
//         PRODUCT_00004,
//         PRODUCT_00005,
//         PRODUCT_00006,
//         PRODUCT_00007,
//         PRODUCT_00008,
//         PRODUCT_00009,
//         PRODUCT_00010,
//     >>>>>>> origin/master



/**
 * IMPORTANT: circular references!!!
 *
 * Products can't have circular references because next.js serializes objects to JSON (to move them from server to client)
 * 1. relatedProducts field is available ONLY on the "first level" of nesting.
 * 2. ProductVariant has productId field (not product object).
 */
let products = [
{
    ...PRODUCT_00001,
    relatedProducts: [PRODUCT_00001, PRODUCT_00002, PRODUCT_00003],
},
{
    ...PRODUCT_00002,
    relatedProducts: [PRODUCT_00001, PRODUCT_00002, PRODUCT_00003],
},
{
    ...PRODUCT_00003,
    relatedProducts: [PRODUCT_00001, PRODUCT_00002, PRODUCT_00003],
},
{
    ...PRODUCT_00004,
    relatedProducts: [PRODUCT_00004],
},
{
    ...PRODUCT_00005,
    relatedProducts: [PRODUCT_00005],
},
{
    ...PRODUCT_00006,
    relatedProducts: [PRODUCT_00006],
},
{
    ...PRODUCT_00007,
    relatedProducts: [PRODUCT_00007],
},
{
    ...PRODUCT_00008,
    relatedProducts: [PRODUCT_00009, PRODUCT_00008],
},
{
    ...PRODUCT_00009,
    relatedProducts: [PRODUCT_00009, PRODUCT_00008],
},
{
    ...PRODUCT_00010,
    relatedProducts: [PRODUCT_00010],
},

];

export default products
