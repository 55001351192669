import React from 'react'
import styledBox from "../../styledBox";
import { LinkRaw } from "@commerce-ui/core2/Link";
import {CURRENCY_LANG_MAP} from "../../langs";
// import { getLang } from "../../helpers/setLang";

import { useRouter } from "next/router";

const columnWidth = '190px';

const Root = styledBox({
    color: 'white',
    display: 'grid',
    gridTemplateColumns: [
        `1fr`,
        `1fr`,
        `1fr`,
        `repeat(4, ${columnWidth})`,
        `repeat(4, ${columnWidth})`,
        `repeat(4, ${columnWidth})`,
    ],
    gridRowGap: 's8'
})

const SecondCol = styledBox({gridColumn: 'span 4'})
const Title = styledBox({font: 'heading02', mb: 's7'})
//
// const Indicator = styledBox({
//     position: 'absolute',
//     left: '-16px',
//     width: '5px',
//     height: '5px',
//     bg: 'white',
//     borderRadius: '50%'
// })
// const LanguageItem = styledBox({font: 'heading01', display: 'flex', alignItems: 'center', position: 'relative'})

const Continents = styledBox({
    display: 'grid',
    gridTemplateColumns: [
        `1fr`,
        null,
        null,
        `repeat(4, minmax(min-content, ${columnWidth}) )`,
    ],
    gridTemplateRows: [
        'auto',
        'auto',
        'repeat(30,auto)',
        'repeat(15,auto)',
        'repeat(15,auto)',
        'repeat(15,auto)'
    ],
    gridRowGap: [
        's4',
        's4',
        's1',
        's1',
        's1',
        's1'
    ],
    gridAutoFlow: [
        'row',
        'row',
        'column',
        'column',
        'column',
        'column',
    ],
    pb: [
        's8',
        's8',
        's8',
        null
    ]
});

const ContinentTitle = styledBox({
    color: 'mono600',
    font: 'body01',
    textTransform: 'uppercase',
    alignSelf: 'center'
})

const Country = styledBox({display: 'flex', alignItems: 'center', position: 'relative'})
const CountryTitle = styledBox({font: 'heading01'})
const CurrencyCode = styledBox({font: 'heading04', ml: '0.25em'})


const HeaderCountrySelectionOverlay = ({shippingContinents}) => {

    const router = useRouter();

    return <Root>
        <SecondCol>
            <Title>Shipping</Title>
            <Continents>
                {
                    shippingContinents.map((continent, i) => <React.Fragment key={i}>
                            {
                                i !== 0 && // ghost
                                <div />
                            }
                            <ContinentTitle>{continent.title}</ContinentTitle>
                            {
                                continent.countries.map((country, j) =>
                                    <LinkRaw
                                      href={`/${CURRENCY_LANG_MAP[country.currencyCode]}${router.asPath.substring(3,router.asPath.length)}`}
                                      key={i + "_" + j}
                                      onClick={()=> {
                                          document.cookie = "preferredLang=" + CURRENCY_LANG_MAP[country.currencyCode].replace("/","")
                                      }}
                                    >

                                        <Country>
                                            {/*{*/}
                                                {/*i === 4 && j === 2 &&*/}
                                                {/*<Indicator/>*/}
                                            {/*}*/}
                                            <CountryTitle>{country.title}</CountryTitle>
                                            <CurrencyCode> ({country.currencyCode})</CurrencyCode>
                                        </Country>

                                    </LinkRaw>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </Continents>
        </SecondCol>
    </Root>
};

export default HeaderCountrySelectionOverlay
