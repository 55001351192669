import React, {useState, useRef, useEffect} from 'react'
import Button from "@button";

import styledBox from "../../styledBox";
import {lin} from "@commerce-ui/core2";
import DialogHeader from "../DialogHeader";
import Dialog from "@commerce-ui/core2/Dialog";
import Input from "../Input";
import ButtonPrimary from "../buttons/ButtonPrimary";
import NewsletterCheckbox from "../NewsletterCheckbox";

import Analytics from "../../helpers/analytics";

const Content = styledBox({
  px: lin('s9','s11'),
  pt: lin('s7','s8'),
  pb: lin('s8','s10'),
  display: 'grid',
  gridRowGap: 's9',
})

const Wrap = styledBox({
  bg: 'cream',
  zIndex: 1,
  maxWidth: [null,null,null,480],
  width: '100%',
})

const Root = styledBox({
  position: 'relative',
  display: [
    null,
    null,
    null,
    'flex'
  ],
  alignItems: 'flex-end',
  justifyContent: 'flex-end', height: [
    null,
    null,
    null,
    '100vh'
  ],
  p: [
    null,
    null,
    null,
    'containerMargin'
  ],
});

const Title = styledBox({
  font: 'heading08',
  textAlign: 'center',
})

const FormGrid = styledBox("form", {
  display: 'grid',
  gridGap: 's7'
})
const TwoCols = styledBox({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: 's7'
})


const DateInputWrapper = styledBox({
  "span" : {
    font: 'input01',
    color: 'mono600',
    width: '100%'
  },
  "input" : {
    width: '100%'
  }
})
const Closer = styledBox({
  position: 'absolute',
  top: 0, left: 0, width: '100%', height: '100%',
  zIndex: 0,
  display: [
    'none',
    null,
    null,
    'block'
  ],
})
const HeaderWrap = styledBox({
  display: props => props.hideOnDesktop ?[
    'block',
    null,
    null,
    'none'
  ] : [
    'none',
    null,
    null,
    'block'
  ],
  bg: 'cream'
})

const NewsletterPopupContent = ({close}) => {

  // 0 - initial
  // 1 - inputs
  // 2 - loading
  // 3 - success
  // 4 - error

  const [step, setStep] = useState(0)

  const [checkboxError, setCheckboxError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const refCheckbox = useRef(null);
  const refEmail = useRef(null);
  const refFirstName = useRef(null);
  const refLastName = useRef(null);
  const refBirthDate = useRef(null);



  const onSubmit = async (e) => {
    e.preventDefault();

    if(!refCheckbox.current.checked) {
      setCheckboxError(true);
      return
    }

    setStep(2);

    // convert from MM / DD / YYYY to  MM/DD/YYY
    let birthday = refBirthDate.current.value.replace(/\s/g, '')

    let email = refEmail.current.value;

    let _data = {
      "profiles":[
        {
          "email": refEmail.current.value ,
          "first_name" : refFirstName.current.value,
          "last_name" : refLastName.current.value,
          "Birthday" : birthday,
          "source" : "website_popup"
        }
      ]
    };


    fetch('/api/klaviyo_subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data),
    })
      .then(async (response)=> {

        let res = await response.json();

        if(response.status === 200) {

          Analytics.newsletterSignup(email);

          setStep(3);
          setTimeout(()=> { close() }, 5000)
        } else {
          setStep(4)
          setErrorMessage(res.detail);
          setTimeout(()=> { setStep(1) }, 5000)
        }
      })
      .catch((error) => {
        setStep(4);
        setErrorMessage('An error occurred, please refresh this page and try again.');
        setTimeout(()=> { setStep(1) }, 5000)
        console.error('Error:', error);
      });
  }

  return <Root>
    <Closer onClick={close}/>
    <Wrap>

        <HeaderWrap>
          <DialogHeader close={close} title={"Newsletter"}/>
        </HeaderWrap>
        <Content>
        {
          step < 2 &&
          <Title>
            Receive updates and special offers from Magda Butrym
          </Title>
        }
        {
          step === 0 &&  <>
            <Button sx={{
              font: 'heading01',
              height: 35,
              width: '100%',
              textAlign: 'center',
              border: '1px solid',
              borderColor: 'black',
            }}
              onClick={()=>setStep(1)}
            >Sign up</Button>
          </>
        }
        {
          step === 1 && <FormGrid onSubmit={onSubmit}>
              <TwoCols>
                <Input
                  id={'first-name'}
                  placeholder={"First name"}
                  ref={refFirstName}
                />
                <Input
                  id={'last-name'}
                  placeholder={"Last name"}
                  ref={refLastName}
                />
              </TwoCols>
              <Input
                id={'email-name'}
                placeholder={"Email address *"}
                type={"email"}
                ref={refEmail}
                required
              />


            <DateInputWrapper>
              <span>Birth Date</span>
              <Input
                placeholder={"MM / DD / YYYY"}
                ref={refBirthDate}
                type={"tel"}
                onChange={ event => {
                  let inputValue = event.target.value

                  if (/\D\/$/.test(inputValue)) inputValue = inputValue.substr(0, inputValue.length - 3);

                  let values = inputValue.split('/').map(function(v) {
                    return v.replace(/\D/g, '')
                  });

                  if (values[1] && values[1].length > 2) {
                    values[1] = values[1].substr(0,2)
                  }

                  event.target.value = values.map(function(v, i) {
                    return v.length === 2 && i < 2 ? v + ' / ' : v;
                  }).join('').substr(0, 14);

                }}
              />
            </DateInputWrapper>


            <NewsletterCheckbox
              onChange={() => refCheckbox.current.value ? setCheckboxError(false) : null}
              hasError={checkboxError}
              ref={refCheckbox}
              id={"newsletter-popup-checkbox"}
            />

            <ButtonPrimary type={'submit'}>Sign up</ButtonPrimary>

            </FormGrid>

        }
        {
          step === 2 && <Title>Sending...</Title>
        }
        {
          step === 3 && <Title>Thank you for subscribing.</Title>
        }
        {
          step === 4 && <Title isError>{errorMessage}</Title>
        }
      </Content>
    </Wrap>

  </Root>
}



const NewsletterPopup = ({isOpen, close}) => {

  return <Dialog
    width={"100%"}
    anchoredTo={["window"]}
    placement={["bottom",null,null,"center"]}
    height={['auto',null,null,'100%']}
    isOpen={isOpen}
    onClose={close}
    header={<HeaderWrap hideOnDesktop><DialogHeader close={close} title={"Newsletter"}/></HeaderWrap>}
  >
    <NewsletterPopupContent close={close}/>
  </Dialog>

};
export default NewsletterPopup
