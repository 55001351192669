import createImage from "../helpers/createImage";

const URL_BASE = "https://ratio-assets.s3.eu-central-1.amazonaws.com/magda-butrym/images/"

const image01 = createImage({
  id: "00001",
  altText: "alt",
  originalSrc: URL_BASE + "image01.png",
  width: 1400,
  height: 702

})
const image02 = createImage({
  id: "00002",
  altText: "alt",
  originalSrc: URL_BASE + "image02.png",
  width: 1400,
  height: 724
})
const image03 = createImage({
  id: "00003",
  altText: "alt",
  originalSrc: URL_BASE + "image03.png",
  width: 700,
  height: 850
})
const image04 = createImage({
  id: "00004",
  altText: "alt",
  originalSrc: URL_BASE + "image04.png",
  width: 700,
  height: 850
})
const image05 = createImage({
  id: "00005",
  altText: "alt",
  originalSrc: URL_BASE + "image05.png",
  width: 1400,
  height: 850
})
const image06 = createImage({
  id: "00006",
  altText: "alt",
  originalSrc: URL_BASE + "image06.png",
  width: 1400,
  height: 850
})
const image07 = createImage({
  id: "00007",
  altText: "alt",
  originalSrc: URL_BASE + "image07.png",
  width: 1400,
  height: 850
})
const image08 = createImage({
  id: "00008",
  altText: "alt",
  originalSrc: URL_BASE + "image08.png",
  width: 1400,
  height: 850
})
const image09 = createImage({
  id: "00009",
  altText: "alt",
  originalSrc: URL_BASE + "image09.png",
  width: 700,
  height: 850
});
const image10 = createImage({
  id: "00010",
  altText: "alt",
  originalSrc: URL_BASE + "image10.png",
  width: 700,
  height: 850
});

const image11 = createImage({
  id: "00011",
  altText: "alt",
  originalSrc: URL_BASE + "product01.png",
  width: 468,
  height: 660
});

const image12 = createImage({
  id: "00012",
  altText: "alt",
  originalSrc: URL_BASE + "product02.png",
  width: 468,
  height: 660
});

const image20 = createImage({
  id: "00020",
  altText: "alt",
  originalSrc: URL_BASE + "image20.png",
  width: 1400,
  height: 724
});
const image21 = createImage({
  id: "00021",
  altText: "alt",
  originalSrc: URL_BASE + "image21.png",
  width: 1400,
  height: 724
});
const image31 = createImage({
  id: "00031",
  altText: "alt",
  originalSrc: URL_BASE + "image_horizontal_01.png",
  width: 567,
  height: 851
});
const image32 = createImage({
  id: "00032",
  altText: "alt",
  originalSrc: URL_BASE + "image_horizontal_02.png",
  width: 567,
  height: 851
});
const image33 = createImage({
  id: "00033",
  altText: "alt",
  originalSrc: URL_BASE + "image_horizontal_03.png",
  width: 567,
  height: 851
});
const image34 = createImage({
  id: "00034",
  altText: "alt",
  originalSrc: URL_BASE + "image_horizontal_04.png",
  width: 567,
  height: 851
});
const image35 = createImage({
  id: "00035",
  altText: "alt",
  originalSrc: URL_BASE + "image_horizontal_05.png",
  width: 567,
  height: 851
});

const productImages = [
  createImage({
    id: "00101",
    altText: "alt",
    originalSrc: URL_BASE + "product01.png",
    width: 468,
    height: 660
  }),
  createImage({
    id: "00102",
    altText: "alt",
    originalSrc: URL_BASE + "product02.png",
    width: 468,
    height: 660
  }),
  createImage({
    id: "00103",
    altText: "alt",
    originalSrc: URL_BASE + "product03-01.png",
    width: 602,
    height: 850
  }),
  createImage({
    id: "00104",
    altText: "alt",
    originalSrc: URL_BASE + "product04.png",
    width: 315,
    height: 445
  }),
  createImage({
    id: "00105",
    altText: "alt",
    originalSrc: URL_BASE + "product05.png",
    width: 315,
    height: 445
  }),
  createImage({
    id: "00106",
    altText: "alt",
    originalSrc: URL_BASE + "product06.png",
    width: 315,
    height: 445
  }),
  createImage({
    id: "00107",
    altText: "alt",
    originalSrc: URL_BASE + "product07.png",
    width: 315,
    height: 445

  }),
  createImage({
    id: "00108",
    altText: "alt",
    originalSrc: URL_BASE + "product08.png",
    width: 315,
    height: 445
  }),
  createImage({
    id: "00109",
    altText: "alt",
    originalSrc: URL_BASE + "product09.png",
    width: 315,
    height: 445

  }),
  createImage({
    id: "00110",
    altText: "alt",
    originalSrc: URL_BASE + "product10.png",
    width: 315,
    height: 445
  }),
  createImage({
    id: "00103-02",
    altText: "alt",
    originalSrc: URL_BASE + "product03-02.png",
    width: 662,
    height: 934
  }),
  createImage({
    id: "00103-03",
    altText: "alt",
    originalSrc: URL_BASE + "product03-03.png",
    width: 700,
    height: 850

  })
];
const productImagesClean = [
  createImage({
    id: "20001",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00001.png",
    width: 316,
    height: 445,
  }),
  createImage({
    id: "20002",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00002.png",
    width: 316,
    height: 445,
  }),
  createImage({
    id: "20003",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00003.png",
    width: 316,
    height: 445,
  }),
  createImage({
    id: "20004",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00004.png",
    width: 316,
    height: 445,
  }),
  createImage({
    id: "20005",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00005.png",
    width: 316,
    height: 445,
  }),
  createImage({
    id: "20006",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00006.png",
    width: 316,
    height: 445,
  }),
  createImage({
    id: "20007",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00007.png",
    width: 316,
    height: 445,
  }),
  createImage({
    id: "20008",
    altText: 'product-name',
    originalSrc: URL_BASE + "product00008.png",
    width: 316,
    height: 445,
  })
];
const storyImages = [
  createImage({
    id: "10001",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00001_2.png",
    width: 496,
    height: 659,
  }),
  createImage({
    id: "10002",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00002.png",
    width: 1038,
    height: 690,
  }),
  createImage({
    id: "10003",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00003_2.png",
    width: 675,
    height: 899,
  }),
  createImage({
    id: "10004",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00004.png",
    width: 1475,
    height: 983,
  }),
  createImage({
    id: "10005",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00005.png",
    width: 1003,
    height: 1275,
  }),
  createImage({
    id: "10006",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00006.png",
    width: 753,
    height: 931,
  }),
  createImage({
    id: "10007",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00007.png",
    width: 707,
    height: 1061,
  }),
  createImage({
    id: "10008",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00008.png",
    width: 711,
    height: 950,
  }),
  createImage({
    id: "10009",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00009.png",
    width: 915,
    height: 1186,
  }),
  createImage(
    {
    id: "10010",
    altText: 'product-name',
    originalSrc: URL_BASE + "story00010.png",
    width: 1460,
    height: 973,
  })

];

const storesImages = [
  createImage(
    {
      id: "10101",
      altText: '',
      originalSrc: URL_BASE + "stores01.png",
      width: 1400,
      height: 850,
    }),
  createImage(
    {
      id: "10102",
      altText: '',
      originalSrc: URL_BASE + "stores02.png",
      width: 1400,
      height: 850,
    }),
  createImage(
    {
      id: "10103",
      altText: '',
      originalSrc: URL_BASE + "stores03.png",
      width: 700,
      height: 850,
    }),
  createImage(
    {
      id: "10104",
      altText: '',
      originalSrc: URL_BASE + "stores04.png",
      width: 700,
      height: 850,
    }),
  createImage(
    {
      id: "10105",
      altText: '',
      originalSrc: URL_BASE + "stores05.png",
      width: 1400,
      height: 850,
    }),
]

const collectionsImages = [
  createImage(
    {
      id: "10201",
      altText: '',
      originalSrc: URL_BASE + "collections01_2.png",
      width: 1400,
      height: 850,
    }),
  createImage(
    {
      id: "10202",
      altText: '',
      originalSrc: URL_BASE + "collections02.png",
      width: 1400,
      height: 850,
    }),
  createImage(
    {
      id: "10203",
      altText: '',
      originalSrc: URL_BASE + "collections03.png",
      width: 1400,
      height: 850,
    }),
  createImage(
    {
      id: "10204",
      altText: '',
      originalSrc: URL_BASE + "collections04.png",
      width: 1400,
      height: 850,
    })
];

const image404 = createImage({
  id: "00404",
  altText: "alt",
  originalSrc: URL_BASE + "404.png",
  width: 1400,
  height: 850
});
const aboutImage = createImage({
  id: "00501",
  altText: "alt",
  originalSrc: URL_BASE + "about01.png",
  width: 1400,
  height: 850
});
const aboutImage2 = createImage({
  id: "00502",
  altText: "alt",
  originalSrc: URL_BASE + "about02.png",
  width: 375,
  height: 667
});
export { image01, image02, image03, image04, image05, image06, image07, image08, image09, image10, image11, image12, image20, image21, productImages, productImagesClean, storyImages,
image31, image32, image33, image34, image35, storesImages, collectionsImages, image404, aboutImage, aboutImage2};
